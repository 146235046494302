<script lang="ts">
  import type { FieldConfig } from 'scripts/utils/types';
  import InvalidMessage from './InvalidMessage.svelte';
  import { onMount } from 'svelte';

  export let value;
  export let disabled = false;
  export let config: FieldConfig;
  let inputComponent;
  let invalidMessage = '';
  let focus = false;

  let selectEl: HTMLElement;

  $: {
    if (disabled) {
      value = undefined;
    }
  }
  /*$: {
    // Option.value にテキスト以外の数値が入るとOnblurの時にLabelが重なるのを避ける
    if (selectEl) {
      // console.log(selectEl.querySelector('.mdc-floating-label'));
      selectEl
        .querySelector('.mdc-select__anchor')
        .addEventListener('blur', (e) => {
          if (value) {
            selectEl
              .querySelector('.mdc-floating-label')
              .classList.add('mdc-floating-label--float-above');
          }
        });
    }
  }*/

  // $: config.input = inputComponent?.getElement();
  onMount(() => {
    setInput(inputComponent);
    validation(value);
  });

  const validation = (value) => {
    if (config.input) {
      config.input.setCustomValidity(config.customValidation(value));
    }
    config.invalidMessage = config.input?.validationMessage;
    config.is_valid = config.input?.validity.valid;
    // console.log(config.input?.validity, config.invalidMessage);
  };

  const setInput = (inputComponent) => {
    if (inputComponent) config.input = inputComponent;
  };

  // $: setInput(inputComponent);
  $: validation(value);
</script>

<div class={config.inputClass}>
  {#if config.options.choices}
    <div class="input-group" style="width:100%" bind:this={selectEl}>
      {#if config.label_display}
        <label class="input-group-text" for="id-{config.name}">
          {config.options.label}
        </label>
      {/if}
      <select
        class="form-select"
        id="id-{config.name}"
        bind:value
        bind:this={inputComponent}
        required={config.options.required}
        placeholder={config.options.label}
        {disabled}
        on:focusout={() => validation(value)}
        on:focusout={() => (focus = false)}
        on:focus={() => (focus = true)}
      >
        {#if !config.options.required}
          <option value="" selected>---------------</option>
        {/if}
        {#each config.options.choices as choice}
          <option
            value={choice.value}
            style={choice.hidden ? 'display:none' : ''}
          >
            {choice.display_name}
          </option>
        {/each}
      </select>
    </div>
    <InvalidMessage
      message={config.invalidMessage || config.helperText}
      is_valid={config.is_valid}
      {focus}
    />
  {/if}
</div>
