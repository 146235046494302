<script lang="ts">
  /** labelの入力で切り替えが可能なTabとTabPanelを生成する。
   *slot部分にはtabsの数と同数のCustomTabsが入り、CustomTabsのSlotにはtabのコンテンツが入る。
   */
  import Tab, { Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  // tabのラベルとなる文字列のリスト
  export let tabs = [];
  // アクティブになっているラベルの文字列
  export let active: string | number = '';

  // $: console.log(active);
</script>

<TabBar {tabs} let:tab bind:active>
  <!-- Note: the `tab` property is required! -->
  <Tab {tab} minWidth>
    <Label>{tab}</Label>
  </Tab>
</TabBar>

<slot><em>no content was provided</em></slot>
