<script lang="ts">
  // slot部分をモーダルウィンドウ化するコンポーネント
  import Dialog, { Title, Content } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import { createEventDispatcher } from 'svelte';

  export let open = false;
  export let title = '';
  export let btnLabel = '新規部門登録';
  export let btnStyle = 'font-size: 12px';

  type AreaSize = 'small' | 'middle' | 'large';
  export let size: AreaSize = 'middle';

  let dialogWidth = '30';

  const dispatch = createEventDispatcher();
  const onOpenButtonClick = () => {
    open = !open;
    if (open) {
      dispatch('modalOpen', open);
    }
  };

  $: {
    if (size === 'small') {
      dialogWidth = '20';
    } else if (size === 'middle') {
      dialogWidth = '35';
    } else if (size === 'large') {
      dialogWidth = '50';
    }
  }
</script>

<div style="text-align:center;">
  <Button on:click={onOpenButtonClick} variant="raised" style={btnStyle}>
    <Label>{btnLabel}</Label>
  </Button>
</div>
<Dialog
  bind:open
  aria-labelledby="title"
  aria-describedby="content"
  surface$style={`width: ${dialogWidth}rem; max-width: calc(100vw - 5rem);`}
>
  <Title style="text-align: center;" id="title">{title}</Title>
  <Content id="content">
    <slot />
  </Content>
</Dialog>
