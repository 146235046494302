<script lang="ts">
  import type { TypeSlipDetail } from 'scripts/purchase/types';
  import { routeSalesApp } from 'scripts/router';
  import { slipTypeChoice } from 'scripts/purchase/config/store';
  import { dateToStr } from 'scripts/utils/date_utils';
  import { fade, fly } from 'svelte/transition';
  import PurchaseOrderForm from './child/PurchaseOrderForm.svelte';
  import SlipForm from './child/SlipForm.svelte';
  export let params;
  let slip: TypeSlipDetail;
  // 伝票種類の初期値
  let initialSlipType: string;
  let slipDisplay = true;
  let formDisplay = false;
  let btnLabel = '発注書作成';

  // $: console.log(slip);
  $: if (slip?.purchase_order) btnLabel = '発注書編集';
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

{#if slipDisplay}
  <div
    in:fade={{ duration: 100 }}
    out:fly={{ y: 200, duration: 500 }}
    on:outroend={() => (formDisplay = true)}
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">入出庫伝票</div>
          <div class="card-body">
            <div class="row ps-3">
              <div class="col-6">
                <div>伝票番号 : {slip?.number}</div>
              </div>
              <div class="col-6">
                {#if ['out', 'keep'].includes(initialSlipType)}
                  売上伝票 : <a href={routeSalesApp.home(`${slip?.order}`)}
                    >{slip?.order || ''}</a
                  >
                {:else}
                  発注書 : <a
                    href={`/purchases/order-sheet/${slip?.purchase_order}/`}
                  >
                    {slip?.purchase_order || ''}
                  </a>
                {/if}
              </div>
            </div>
            {#if ['in', 'out'].includes(initialSlipType)}
              <div class="row ps-3">
                <div class="col-6">
                  <div>伝票種類 : {$slipTypeChoice[initialSlipType]}</div>
                </div>
                <div class="col-6">
                  日付 : {dateToStr(new Date(slip?.date_created))
                    .YMDhmJPformat || ''}
                </div>
              </div>
            {/if}
            <div class="row">
              <div class="col-12">
                <div>
                  <SlipForm bind:slip bind:initialSlipType slipPk={params.id} />
                </div>
                <div class="w-100 d-flex justify-content-center mt-4 mb-3">
                  {#if ['wait'].includes(slip?.type)}
                    <button
                      class="btn btn-success"
                      on:click={() => (slipDisplay = false)}
                    >
                      {btnLabel}
                    </button>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
{#if formDisplay}
  <div
    class="row mt-4"
    in:fade={{ duration: 100 }}
    out:fly={{ y: 200, duration: 500 }}
    on:outroend={() => (slipDisplay = true)}
  >
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <PurchaseOrderForm bind:slip bind:formDisplay />
        </div>
      </div>
    </div>
  </div>
{/if}
