<script lang="ts">
  /**
   * サーバーから取得したoptionsからフォームを生成する汎用コンポーネント
   * POST, PUTなどのメソッドはstate[primaryKey]の値がnullかどうかで更新、新規作成を自動判定し、
   * onsubmitファンクションでmethod, endpointを変更する。
   */
  import Button, { Label } from '@smui/button';
  import { fetchCustomRequest } from 'scripts/utils/fetcher';
  import type {
    DispatchMessage,
    ErrorResponse,
    FieldConfig,
    FormConfig,
    ResultWithData,
  } from 'scripts/utils/types';
  import { mergeFormConfig } from 'scripts/utils/utils';
  import { createEventDispatcher, onMount } from 'svelte';
  import FadeCollapse from './FadeCollapse.svelte';
  import { deconstractError } from 'scripts/utils/form_utils';

  export let modelName: string;
  export let formConfig: FormConfig = {};
  export let endpoint: string;
  let post_endpoint = '';
  export let state: Object;
  export let primaryKey: string;
  /** 親コンポーネント定義のカスタムバリデーション */
  export let confirmFunction: Function = (): ResultWithData => {
    // dataはエラー発生しているfieldNameをkey、エラーメッセージのarrayをvalueとするオブジェクト
    // success == trueの場合、dataは空のオブジェクトにする
    return {
      success: true,
      data: {},
      error: [''],
    };
  };
  export let deleteButtonDisplay = false;
  export let cancelButtonDisplay = true;
  export let submitButtonLabel = '登録';

  let responseErrors: ErrorResponse = {};
  let alertMessage = '';

  onMount(() => {
    initializeState();
  });

  // html input type attribute
  const inputTypeSelector = {
    string: 'text',
    email: 'email',
    choice: null,
    boolean: 'checkbox',
    slug: 'text',
    integer: 'number',
  };

  /* const dispatch = createEventDispatcher()
  const formSubmit = () => {
    dispatch('formSubmit', {''});
  };*/
  export const customValidation = () => {
    const res = confirmFunction();
    responseErrors = res.data;

    return res.success;
  };

  /** formConfig[key].optioinsで指定された制約を満たしているかをチェック。
  HTMLInputElementのvalidity.validが全てtrueであればtrueを返す
  */
  export const formIsValid = () => {
    let valid = false;
    // console.log(fieldConfigs);
    const result = Object.values(mergeFormConfig(formConfig))
      .map((config: FieldConfig) => config.input?.validity?.valid)
      .filter((val) => val !== undefined)
      .reduce((prev, curr) => prev && curr, true);
    // console.log(result);
    valid = result;
    return valid;
  };

  /** formConfigから初期値を取得して初期化 */
  const initializeState = () => {
    Object.values(formConfig).forEach((fieldConfigs) => {
      Object.entries(fieldConfigs).forEach(([key, config]) => {
        state[key] = config.initialValue;
      });
    });
    responseErrors = {};
  };

  const dispatch = createEventDispatcher();
  /** 登録、削除など、フォームで行う処理が完了した際に発火するイベント */
  const onProcessEnd = (detail: DispatchMessage) => {
    if (detail.success) initializeState();
    dispatch('processEnd', detail);
    /** 処理が完了したらstateを初期化する */
    //initializeState();
  };

  const upload = async (
    method: 'POST' | 'PUT' | 'DELETE',
    post_endpoint: string,
  ) => {
    if (customValidation()) {
      if (formIsValid()) {
        // console.log('post_data', state);
        // const res = { success: true, error: '' };
        const res = await fetchCustomRequest({
          endpoint: post_endpoint,
          method: method,
          fetchObj: state,
        });
        if (res.success) {
          // state = res.success;
          onProcessEnd({
            success: true,
            updated: true,
            data: res,
          });
          let purpose = '';
          if (method === 'POST') {
            purpose = `${modelName}登録`;
          } else if (method === 'PUT') {
            purpose = `${modelName}更新`;
          } else if (method === 'DELETE') {
            purpose = `${modelName}削除`;
          }
          alertMessage = `${purpose}を行いました。`;
        } else {
          responseErrors = res.error;
        }
      } else
        alert(
          'フォームに不正な値が入力されています。入力値を修正し、再度登録ボタンを押してください',
        );
    }
  };

  const onSubmit = async () => {
    let pk = undefined;
    let method: 'POST' | 'PUT' = 'POST';
    if (state[primaryKey]) {
      pk = state[primaryKey];
      post_endpoint = `${endpoint}${pk}/`;
      method = 'PUT';
    } else {
      post_endpoint = endpoint;
    }
    // console.log(post_endpoint, method, state);
    upload(method, post_endpoint);
  };

  const onDelete = async () => {
    if (deleteButtonDisplay) {
      let pk = undefined;
      let method: 'DELETE';
      if (state[primaryKey]) {
        pk = state[primaryKey];
        post_endpoint = `${endpoint}${pk}/`;
        upload(method, post_endpoint);
      } else {
        alertMessage = '削除する商品が指定されていません。';
      }
      // console.log('post_data', state);
    }
  };

  const dispAlert = (message: string) => {
    if (message) alert(message);
  };

  $: if (state) customValidation();
  $: dispAlert(alertMessage);
  // $: console.log('form state', state);
</script>

<div class="col-12 mx-auto">
  {#if state}
    {#each Object.values(formConfig) as fieldConfigs}
      <div class="row">
        {#each Object.entries(fieldConfigs) as [key, config]}
          {#if !config.options.read_only}
            <svelte:component
              this={config.component}
              bind:value={state[key]}
              bind:config
            />
          {/if}
        {/each}
      </div>
    {/each}
  {/if}
  <div class="response-errors text-start">
    <FadeCollapse
      expand={responseErrors && Object.keys(responseErrors).length > 0}
    >
      <h6 style="font-weight: bold">下記の項目でエラーが発生しました。</h6>
      {@html deconstractError(responseErrors, formConfig, '')}
      <!--
      {#each Object.entries(responseErrors) as [key, errors]}
        <h6>
          {key.includes('ROW')
            ? mergeFormConfig(formConfig)[key].options.label
            : key}
        </h6>
        <ul>
          <li class="error-content error-color">
            {#if Array.isArray(errors)}
              {#each errors as errorContent}
                {#if typeof errorContent == 'string'}
                  {errorContent}<br />
                {:else}
                  {#each Object.entries(errorContent) as [content_key, text_array]}
                    {content_key}: {text_array[0]}<br />
                  {/each}
                {/if}
              {/each}
            {:else}
              {errors}
            {/if}
          </li>
        </ul>
      {/each}
      -->
    </FadeCollapse>
  </div>
</div>
<div>
  <slot />
</div>
<div class="button-shell">
  {#if cancelButtonDisplay}
    <Button
      on:click={() => {
        onProcessEnd({
          updated: false,
          data: null,
          success: true,
        });
      }}
      variant="outlined"
      class="mx-2"
    >
      <Label class="font-weight-bold text-muted">キャンセル</Label>
    </Button>
  {/if}
  {#if deleteButtonDisplay}
    <Button
      on:click={() => {
        onDelete();
      }}
      style="background-color: #D81B60"
      variant="raised"
      class="mx-2"
    >
      <Label class="font-weight-bold">削除</Label>
    </Button>
  {/if}
  <Button
    on:click={() => {
      onSubmit();
    }}
    variant="raised"
    class="mx-2"
    style="width: {cancelButtonDisplay ? 8 : 20}rem;"
  >
    <Label class="font-weight-bold">{submitButtonLabel}</Label>
  </Button>
</div>

<style>
  .button-shell {
    padding: 1rem;
    text-align: center;
  }
</style>
