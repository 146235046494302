import type { TypeSlipItem } from 'scripts/purchase/types';
import type { ItemInfo, ItemInfoObj, OrderObj, Receipt, TypeOrderItem } from 'scripts/sales/types'

/** slipitem_setから発注金額の合計を計算 */
export const getSlipTotal = (slipitem_set: TypeSlipItem[]) => {
    let priceTotal = 0;
    slipitem_set.forEach((slipItem) => {
        priceTotal += slipItem.value_changed;
    });
    return priceTotal;
};


/** OrderItemsからReceiptを作成 */
export const calcReceipt = (order: OrderObj, orderItems: TypeOrderItem[], items: ItemInfo[]): Receipt => {
    let receipt = order.receipt
    // receipt初期化
    Object.keys(receipt).forEach(rateStr => {
        receipt[rateStr] = { tax_price: 0, target_price: 0 }
    });
    // console.log(items)
    orderItems.forEach((orderItem) => {
        orderItem.get_subtotal = orderItem.quantity * orderItem.price_include_tax
        const itemInfo = items.find((item) => item.code == orderItem.code)
        const taxRate = itemInfo.tax_rate
        const taxRateStr = `${Math.round(taxRate * 100)}%`
        const priceExcludeTax = Math.round(orderItem.get_subtotal / (1 + taxRate))
        receipt[taxRateStr].tax_price += orderItem.get_subtotal - priceExcludeTax
        receipt[taxRateStr].target_price += priceExcludeTax
    });
    return receipt
}

/** Receiptから税込合計を求める */
export const getChargeAmountFromReceipt = (receipt: Receipt) => {
    let total = 0
    Object.values(receipt).forEach((val) => {
        total += (val.target_price + val.tax_price)
    })
    return total
}

export const numToJPY = (num: number) => {
    return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
    }).format(num);
};

/** Receiptから合計の税抜き支払い金額を計算
 */
export const getPaidExcludeTax = (receipt: Receipt) => {
    return Object.values(receipt).reduce(
        (prev, curr) => prev + curr.target_price,
        0,
    );
}

/** Receiptから合計の税額を計算
 */
export const getPaidTax = (receipt: Receipt) => {
    return Object.values(receipt).reduce(
        (prev, curr) => prev + curr.tax_price,
        0,
    );
}