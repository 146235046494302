<script lang="ts">
  // CustomTabs のchild component
  import FadeCollapse from 'scripts/global_components/FadeCollapse.svelte';
  export let active;
  export let name;
</script>

<FadeCollapse expand={name === active} outDuration={0}>
  <slot />
</FadeCollapse>
