<script lang="ts">
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
    SortValue,
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
  import IconButton, { Icon } from '@smui/icon-button';
  import type { TypeTableConfig } from 'scripts/utils/types';
  import Spinner from '../child_components/Spinner.svelte';
  import { onMount } from 'svelte';
  import { Label } from '@smui/button';
  import SelectableRow from './SelectableRow.svelte';

  export let items;
  export let tableConfig: TypeTableConfig;
  export let selectedItems = [];
  // 選択出来る最大行数
  export let maxRows;

  // itemsのプライマリキー。'pk', 'id', etc..
  export let primaryKey: string = 'pk';

  // sort 設定
  let sort = items.length ? String(Object.keys(items[0])[0]) : 'id'; // 変数名sortの変更不可
  export let searchable = true;
  export let displayTableHeader = true;
  export let displayPagination = true;
  export let sortDirection: Lowercase<keyof typeof SortValue> = 'ascending';
  let arrow_direction =
    sortDirection === 'ascending' ? 'arrow_downward' : 'arrow_upward';
  export let tableName: string = '';
  // テーブル表示数の選択肢
  export const rowsSelect = [10, 25, 50, 100];
  export let coloredRow = [];
  export let rowColor = '#e9e9e9';
  let rowsPerPage = rowsSelect[0];
  let currentPage = 0;
  let slice = [];
  let searchResult = [];
  let searchText = '';
  let spinnerDisplay = true;

  onMount(() => {
    spinnerDisplay = false;
  });

  const handleSort = () => {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === 'ascending' ? 'slice' : 'reverse'
      ]();
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      return Number(aVal) - Number(bVal);
    });
    // console.log(sort, items);
    items = items;
  };

  let processing = false;
  // 検索窓にテキストが入力された場合の挙動
  const doSeach = (searchText: string) => {
    // 検索処理が行われている間は入力を無視する
    if (processing) {
      return;
    }
    processing = true;
    const tableFields = Object.keys(tableConfig);
    if (searchText.length) {
      searchResult = items.filter((item) => {
        let results: boolean[] = [];

        Object.entries(item).forEach(([fieldName, val]) => {
          if (
            tableFields.includes(fieldName) &&
            tableConfig[fieldName].choices
          ) {
            const choices = tableConfig[fieldName].choices;
            let result = false;
            if (choices) {
              // console.log(choices);
              const found = choices.find((choice) =>
                choice.display_name.includes(searchText),
              );
              if (found && found.value === val) result = true;
            }
            results.push(result);
          } else if (typeof val === 'string') {
            results.push(val.includes(searchText));
          } else if (typeof val === 'number') {
            results.push(String(val) == searchText);
          }
        });
        return results.includes(true);
      });
    } else {
      searchResult = items;
    }
    currentPage = 0;
    processing = false;
    // console.log('search');
  };

  const onChecked = (e) => {
    const item = e.detail.item;
    const value = e.detail.value;

    let lengthChange = 0;
    if (value) {
      if (!selectedItems.includes(item)) lengthChange = 1;
    } else {
      if (selectedItems.includes(item)) lengthChange = -1;
    }
    const newLength = selectedItems.length + lengthChange;
    if (newLength > maxRows) {
      alert('表示できる最大の行数を超えています。');
    }
    if (value && newLength <= maxRows) {
      selectedItems.push({ ...item });
      selectedItems = selectedItems;
    } else {
      selectedItems = selectedItems.filter(
        (el) => el[primaryKey] != item[primaryKey],
      );
    }
  };

  // 検索窓にテキストが入力された場合の挙動
  $: if (items) doSeach(searchText);
  $: lastPage = Math.max(Math.ceil(searchResult.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, searchResult.length);
  $: {
    slice = searchResult.slice(start, end);
  }
  // $: console.log(selectedItems);
</script>

<svelte:head>
  <style>
    .mdc-data-table__table {
      white-space: unset;
    }
  </style>
</svelte:head>

<Spinner display={spinnerDisplay} />
<h5 class="font-weight-bold text-muted">{tableName}</h5>
<div class="row">
  <div class="d-flex align-items-center mb-2 col-3 col-sm-3" />
  <div class="d-flex align-items-center mb-2 col-8 col-sm-4" />
  <div
    class="d-flex align-items-center mb-2 justify-content-end col-12 col-sm-5"
  >
    {#if searchable}
      <div class="form-floating mb-3 w-100">
        <input
          bind:value={searchText}
          type="text"
          class="form-control w-100"
          placeholder="Search"
        />
        <label class="text-muted" for="floatingInput">検索</label>
      </div>
    {/if}
  </div>
</div>

<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:SMUIDataTable:sorted={handleSort}
  style="width: 100%;"
  class="editable-datatable"
  container$class="editable-datatable-container"
>
  {#if displayTableHeader}
    <Head>
      <Row>
        <!--
        Note: whatever you supply to "columnId" is
        appended with "-status-label" and used as an ID
        for the hidden label that describes the sort
        status to screen readers.

        You can localize those labels with the
        "sortAscendingAriaLabel" and
        "sortDescendingAriaLabel" props on the DataTable.
      -->
        <Cell>select</Cell>
        {#each Object.keys(tableConfig) as key}
          {#if !tableConfig[key].hidden}
            {#if items[0] && typeof items[0][key] === 'number'}
              <Cell numeric columnId={key} style="white-space: nowrap;">
                <!-- For numeric columns, icon comes first. -->
                {tableConfig[key].label}
                <IconButton class="material-icons">{arrow_direction}</IconButton
                >
              </Cell>
            {:else}
              <Cell columnId={key} style="white-space: nowrap;">
                <!-- For numeric columns, icon comes first. -->
                {tableConfig[key].label}
                <IconButton class="material-icons">{arrow_direction}</IconButton
                >
              </Cell>
            {/if}
          {/if}
        {/each}
      </Row>
    </Head>
  {/if}
  <Body>
    {#each slice as item}
      <SelectableRow
        {item}
        {primaryKey}
        {tableConfig}
        bind:selectedItems
        bind:slice
        {coloredRow}
        {rowColor}
        on:trClick
        on:checked={onChecked}
      />
    {/each}
  </Body>
  <Pagination
    slot="paginate"
    style={!displayPagination ? 'display: none;' : ''}
  >
    <svelte:fragment slot="rowsPerPage">
      <Label>Rows Per Page</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        {#each rowsSelect as select}
          <Option value={select}>{select}</Option>
        {/each}
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      <div>
        {start + 1}-{end} of {items.length}
      </div>
    </svelte:fragment>
    <div>
      <IconButton
        class="material-icons"
        action="first-page"
        title="First page"
        style="width: 21%;"
        on:click={() => (currentPage = 0)}
        disabled={currentPage === 0}>first_page</IconButton
      >
      <IconButton
        class="material-icons"
        action="prev-page"
        title="Prev page"
        style="width: 21%;"
        on:click={() => currentPage--}
        disabled={currentPage === 0}>chevron_left</IconButton
      >
      <IconButton
        class="material-icons"
        action="next-page"
        title="Next page"
        style="width: 21%;"
        on:click={() => currentPage++}
        disabled={currentPage === lastPage}>chevron_right</IconButton
      >
      <IconButton
        class="material-icons"
        action="last-page"
        title="Last page"
        style="width: 21%;"
        on:click={() => (currentPage = lastPage)}
        disabled={currentPage === lastPage}>last_page</IconButton
      >
    </div>
  </Pagination>
</DataTable>

<style>
  :global(.editable-datatable-container::-webkit-scrollbar) {
    height: 0.8rem;
  }
  :global(.editable-datatable-container::-webkit-scrollbar-thumb) {
    background: #bcbcbc;
  }
</style>
