import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import type { PageCondition, QueryParams, SearchParam, SearchParamObj, SearchType } from "./types";

export const loadedFiles: Writable<(string | ArrayBuffer)[]> = writable([])



export function storable(data: PageCondition, name: string) {
    const store = writable(data);
    const { subscribe, set, update } = store;
    const isBrowser = typeof window !== 'undefined';
    const strageName = name

    isBrowser && sessionStorage.getItem(strageName) && set(JSON.parse(sessionStorage.getItem(strageName)));

    return {
        subscribe,
        set: n => {
            // console.log(sessionStorage.getItem(name));
            isBrowser && (sessionStorage.setItem(strageName, JSON.stringify(n)));
            // console.log('set', n, strageName);
            set(n);
        },
    };
}


/** pageConditionに保存されているqueryParamsを使用してsearchParamを復元する */
export const rebuildSearchParamObj = (
    searchParamObj: SearchParamObj,
    storedParamObj: SearchParamObj,
    searchFields: string[]
) => {
    searchFields = Object.keys(storedParamObj)
    const rebuildParams = {
        ...searchParamObj,
        ...storedParamObj,
    }
    return {
        searchParamObj: rebuildParams,
        searchFields: searchFields
    }
};