<script lang="ts">
  import Textfield from '@smui/textfield';
  import type { FieldConfig } from 'scripts/utils/types';
  import InvalidMessage from './InvalidMessage.svelte';

  export let config: FieldConfig;
  export let value;

  let inputComponent: HTMLInputElement;
  let invalidMessage = '';
  let focus = false;

  const validation = (value?) => {
    if (config.input) {
      config.input.setCustomValidity(config.customValidation(value));

      invalidMessage = config.input.validationMessage;
      config.is_valid = config.input.validity.valid;
    }

    // console.log(value, config.input?.validity, invalidMessage);
  };

  $: config.input = inputComponent;
  $: validation(value);
</script>

<div class={config.inputClass}>
  {#if config.display}
    <div class="input-group" style="width:100%">
      {#if config.label_display}
        <label for={`id_for_${config.name}`} class="input-group-text"
          >{config.options.label}</label
        >
      {/if}
      <input
        type="password"
        id={`id_for_${config.name}`}
        class="form-control"
        placeholder={config.placeHolder}
        style={config.inputStyle}
        maxlength={config.options.max_length}
        required={config.options.required}
        readonly={config.options.read_only}
        bind:value
        bind:this={inputComponent}
        on:focusout={() => validation(value)}
        on:focusout={() => (focus = false)}
        on:focus={() => (focus = true)}
      />
    </div>
    <InvalidMessage
      message={invalidMessage || config.helperText}
      is_valid={config.is_valid}
      {focus}
    />
  {/if}
</div>
