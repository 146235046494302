<script lang="ts">
  import jsQR from 'jsqr';
  import { createEventDispatcher, onMount } from 'svelte';

  export let value = '';
  /** キャンバスの表示非表示でカメラの軌道を制御する */
  export let videoDisplay = false;

  const initialMessage = '🎥 カメラ起動';
  let loadingMessage = initialMessage;

  var video: HTMLVideoElement = document.createElement('video');
  let canvasElement: HTMLCanvasElement;
  let canvas: CanvasRenderingContext2D;
  // var loadingMessage = document.getElementById('loadingMessage');
  onMount(() => {
    canvasElement = document.querySelector('#canvas');
    canvas = canvasElement.getContext('2d');
  });

  function drawLine(begin, end, color) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  }

  const tick = () => {
    loadingMessage = '⌛ Loading video...';
    //console.log(video.readyState);

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      videoDisplay = true;

      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      var imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height,
      );
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert',
      });
      if (code) {
        drawLine(
          code.location.topLeftCorner,
          code.location.topRightCorner,
          '#FF3B58',
        );
        drawLine(
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          '#FF3B58',
        );
        drawLine(
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          '#FF3B58',
        );
        drawLine(
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          '#FF3B58',
        );
        /** code.dataがASCII文字列の場合は読み込みを終了し、イベント発火 */
        if (/^[\x00-\x7F]+$/.test(code.data)) {
          value = code.data;
          /** video起動中のみQR値が読み込まれたイベントを発火する */
          onQrRead(value);
        } else {
          // console.log('except ascii');
          requestAnimationFrame(tick);
        }
      } else {
        value = '';
        requestAnimationFrame(tick);
      }
    } else {
      if (video?.srcObject) {
        requestAnimationFrame(tick);
      } else loadingMessage = initialMessage;
    }
  };

  const dispatch = createEventDispatcher();
  const onQrRead = (qrValue) => {
    dispatch('qrRead', qrValue);
  };

  const stopCamera = () => {
    if (video?.srcObject) {
      // @ts-ignore
      const tracks = video.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      video.srcObject = null;
    }
    loadingMessage = initialMessage;
  };
  /** ページ遷移前にカメラをOFFにする */
  window.addEventListener('beforeunload', () => {
    stopCamera();
  });

  export const startCamera = () => {
    if (!video.srcObject) {
      initCamera();
    }
    video.play();

    requestAnimationFrame(tick);
  };

  const initCamera = () => {
    value = '';
    // Use facingMode: environment to attemt to get the front camera on phones
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: 'environment' },
      })
      .then(function (stream) {
        video.srcObject = stream;
        video.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
        startCamera();
      });
  };

  $: if (!videoDisplay) stopCamera();
</script>

<div>
  <div id="canvasContainer" class="mx-auto row d-flex justify-content-center">
    {#if !videoDisplay}
      <button on:click={startCamera} id="loadingMessage">
        {@html loadingMessage}
      </button>
    {/if}

    <div class="col-12">
      <canvas id="canvas" hidden={!videoDisplay} />
    </div>
    <div id="output" class="col-12">
      <div><b>Data:</b> <span id="outputData">{value}</span></div>
    </div>
  </div>
</div>

<style>
  #loadingMessage {
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 1rem;
    background-color: #eee;
  }

  @media screen and (max-width: 767px) {
    #canvasContainer {
      width: 80%;
    }
  }
  @media screen and (min-width: 768px) {
    #canvasContainer {
      width: 50%;
    }
  }

  #canvas {
    width: 100%;
    border-radius: 0.5rem;
  }
</style>
