<script>
  import { slide } from 'svelte/transition';

  export let expand = false;
  export let duration = 200;
</script>

{#if expand}
  <div class="content-shell" transition:slide={{ duration: duration }}>
    <slot />
  </div>
{/if}

<style>
  .content-shell {
    padding: 0.5rem;
  }
</style>
