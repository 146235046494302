<script lang="ts">
  import { routeAccounts, routePurchasesApp } from 'scripts/router';
  import type {
    TypePurchaseOrder,
    TypeSlipDetail,
  } from 'scripts/purchase/types';
  import ModelDetailForm from 'scripts/global_components/ModelDetailForm.svelte';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type {
    ChoiceDisplayMap,
    ErrorResponse,
    FormConfig,
    SimpleFormConfig,
  } from 'scripts/utils/types';
  import { onMount } from 'svelte';
  import {
    getAndAppendOptions,
    makeConfigFromOptions,
  } from 'scripts/utils/form_utils';
  import type { TypeBusinessPartner, TypeUser } from 'scripts/accounts/types';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import { navigate } from 'svelte-routing';

  export let slip: TypeSlipDetail;
  export let formDisplay = false;
  let choiceDisplayMap: ChoiceDisplayMap;
  let responseErrors: ErrorResponse = {};

  let businessPartners: TypeBusinessPartner[];
  /** 現在設定されている取引先 */
  let currentPartner;
  let user: TypeUser;

  /** for purchase order*/
  let purchaseOrderEp = routePurchasesApp.purchaseOrderSlipApi();
  let purchaseOrder: TypePurchaseOrder = {
    pk: null,
    ordered_date: `${dateToStr(new Date()).YMDformat}`,
    slip_number: '',
    inout_slip: slip.pk,
    inout_slip_display: slip,
    from_user: null,
    from_user_display: user,
    partner: null,
    company_name: '',
    name: '',
    postal_code: '',
    address1: '',
    address2: '',
    note: '',
  };
  let simpleFormConfig: SimpleFormConfig = {
    Row1: {
      pk: { display: false },
      slip_number: { inputClass: 'col-12 col-md-6' },
      ordered_date: { inputClass: 'col-12 col-md-6' },
    },
    Row2: {
      partner: {},
    },
    Row3: {
      company_name: { inputClass: 'col-12 col-md-6' },
      name: { inputClass: 'col-12 col-md-6' },
      postal_code: { inputClass: 'col-6' },
      address1: { inputClass: 'col-12' },
      address2: { inputClass: 'col-12' },
    },
    Row4: {
      note: { inputClass: 'col-12', inputStyle: 'min-height: 7rem' },
    },
  };
  let formConfig: FormConfig;

  onMount(() => {
    makeDatasets();
  });

  const makeDatasets = async () => {
    const configs = await makeConfigFromOptions(
      purchaseOrderEp,
      simpleFormConfig,
      {},
      { type: 2 },
    );
    formConfig = configs.formConfig;
    choiceDisplayMap = configs.choiceDisplayMap;

    if (slip.purchase_order) {
      const purchaseRes = await fetchGetRequest({
        endpoint: routePurchasesApp.purchaseOrderSlipApi(slip.purchase_order),
        errorMessage: '伝票の取得に失敗しました。',
      });
      if (purchaseRes.success) purchaseOrder = purchaseRes.data;
    } else {
      purchaseOrder.slip_number = `${formConfig.Row1.slip_number.initialValue}`;
    }

    const bpRes = await fetchGetRequest({
      endpoint: `${routeAccounts.businessPartnerApi()}`,
      errorMessage: '取引先情報の取得に失敗しました。',
      method: 'GET',
      params: { type: 2 },
    });
    if (bpRes.success) {
      businessPartners = bpRes.data;
    } else alert(bpRes.error);

    const userRes = await fetchGetRequest({
      endpoint: routeAccounts.userApi(),
      errorMessage: 'ユーザー情報の取得に失敗しました。',
    });
    if (userRes.success) {
      user = userRes.data;
    } else alert(userRes.error);
  };

  const onProcessEnd = (event) => {
    // console.log(event.detail);
    if (event.detail.data == null) {
      formDisplay = false;
    } else {
      if (event.detail.success) {
        const newPurchase = event.detail.data.success;
        navigate(`/purchases/order-sheet/${newPurchase.pk}`);
      } else navigate(`/purchases/order-sheet/`);
    }
  };

  const updatePurchaseOrder = (purchaseOrder: TypePurchaseOrder) => {
    const addressLengthList = [
      purchaseOrder.company_name?.length || 0,
      purchaseOrder.postal_code?.length || 0,
      purchaseOrder.address1?.length || 0,
      purchaseOrder.address2?.length || 0,
    ];
    // 現在のPurchaseOrderに企業の情報が含まれているか
    const addressIncludesValue = addressLengthList.reduce(
      (sum, next) => (sum += next),
      0,
    );
    if (purchaseOrder.partner && businessPartners) {
      if (
        !addressIncludesValue ||
        (currentPartner && purchaseOrder.partner != currentPartner.pk)
      ) {
        currentPartner = businessPartners.find(
          (partner) => partner.pk == purchaseOrder.partner,
        );
        purchaseOrder.company_name = currentPartner.address?.name;
        purchaseOrder.postal_code = currentPartner.address?.postal_code;
        purchaseOrder.address1 = currentPartner.address?.address1 || '';
        purchaseOrder.address2 = currentPartner.address?.address2 || '';
      }
    }
  };

  const userUpdated = (user: TypeUser) => {
    purchaseOrder.from_user = user ? user.pk : null;
  };

  $: purchaseOrder.inout_slip = slip.pk;
  $: updatePurchaseOrder(purchaseOrder);
  $: userUpdated(user);
  // $: console.log(purchaseOrder);
</script>

<ModelDetailForm
  modelName="発注書"
  bind:formConfig
  bind:endpoint={purchaseOrderEp}
  bind:state={purchaseOrder}
  primaryKey="pk"
  cancelButtonDisplay={true}
  on:processEnd={(event) => {
    onProcessEnd(event);
  }}
/>
