<script lang="ts">
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
    SortValue,
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
  //import { Label } from '@smui/common';
  import IconButton, { Icon } from '@smui/icon-button';
  import { createEventDispatcher, onMount } from 'svelte';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type {
    TypeTableConfig,
    TableStatus,
    UpdateItem,
    StandardResult,
    ChoiceDisplayMap,
  } from 'scripts/utils/types';
  import Button, { Label } from '@smui/button';
  import Menu from '@smui/menu';
  import List, { Item, Text } from '@smui/list';
  import {
    downloadCSV,
    getChoiceDisplay,
    makeInitialTableStatusRow,
  } from 'scripts/utils/CsvExportImport';
  import FileInput from './input/FileInput.svelte';
  import Spinner from './child_components/Spinner.svelte';
  import { makeObjectStr } from 'scripts/utils/utils';

  /* make DataTable of Array<Object>
    Objectは同じKey、データ構造を持っている。
    例）Stock : {
        id: number,
        name: string,
        price: number
    }
    親コンポーネントから提供されるデータは下記のとおり
    items: Stock[]
    bulkUpdateItems: BulkUpdateItems {
      primaryKey: {
        fieldKey: fieldValue
        ...
      }
      ...
    }
  */

  export let items;
  export let tableConfig: TypeTableConfig;
  export let choiceDisplayMap: ChoiceDisplayMap = {};
  // itemsのプライマリキー。 一括更新機能を有効にする場合のみ設定する
  export let primaryKey: string = undefined;
  // bulkUpdateの際のユニークキー
  // export let bulkUpdateUniqueKey: string = 'pk';
  // bulkUpdate可能なフィールド
  let bulkUpdateFields: string[];
  /** bulkUpdateのデータ tableStatusの中から更新する値だけを抜き出したもの */
  let bulkUpdateItems: UpdateItem[] = [];
  /** 一括更新用のボタンを表示するか */
  export let displayUpdateButton: boolean = undefined;
  /** id, fieldname の２重キーの中にアップデートするデータを格納 */
  export let tableStatus: TableStatus = {};
  /** テーブルの行を削除するボタンを表示するか */
  export let rowDeletable = false;

  // CSV出力
  export let csvName = 'table.csv';
  export let csvDownload = false;
  export let csvUpload = false;
  export let csvFields = [];
  // CSVインポート
  let clickFileInput;

  // @ts-ignore
  let csvMenu: Menu;

  // sort 設定
  let sort = items.length ? String(Object.keys(items[0])[0]) : 'id'; // 変数名sortの変更不可
  export let searchable = true;
  export let displayTableHeader = true;
  export let displayPagination = true;
  export let sortDirection: Lowercase<keyof typeof SortValue> = 'ascending';
  let arrow_direction =
    sortDirection === 'ascending' ? 'arrow_downward' : 'arrow_upward';
  export let tableName: string = '';
  // テーブル表示数の選択肢
  export const rowsSelect = [10, 18, 25, 50, 100];
  export let coloredRow = [];
  export let rowColor = '#e9e9e9';
  export let rowsPerPage = rowsSelect[0];
  let currentPage = 0;
  let slice = [];
  let searchResult = [];
  let searchText = '';
  let spinnerDisplay = true;

  onMount(() => {
    spinnerDisplay = false;
  });

  const dispatch = createEventDispatcher();
  const trClick = (item) => {
    // console.log(item);
    dispatch('trClick', { item: item });
  };
  /** tableStatusにデータがある場合はデータに更新があったとみなしてbulkUpdateItemsを作成し、dispatchをトリガーする */
  const bulkUpdateClick = () => {
    bulkUpdateItems = [];
    if (primaryKey) {
      Object.entries(tableStatus).forEach(([pk, rowVal]) => {
        const updateData = items.find((item) => item[primaryKey] == pk);
        // const checkDataUpdated = []; // 情報が上書きされているかをチエックする
        bulkUpdateFields.forEach((fieldName) => {
          updateData[fieldName] =
            rowVal[fieldName].value != undefined
              ? rowVal[fieldName].value
              : updateData[fieldName];
          //checkDataUpdated.push(updateData[fieldName] != currentVal);
        });
        // データが上書きされているもののみをbulkUpdateItemsに追加する
        // if (checkDataUpdated.includes(true)) bulkUpdateItems.push(updateData);
        bulkUpdateItems.push(updateData);
      });
      // console.log(bulkUpdateItems);
      dispatch('bulkUpdateClick', { items: bulkUpdateItems });
    } else {
      alert(
        '更新データが入力されていません。データを入力してから更新ボタンを押してください。',
      );
    }
  };

  const handleSort = () => {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === 'ascending' ? 'slice' : 'reverse'
      ]();
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      return Number(aVal) - Number(bVal);
    });
    // console.log(sort, items);
    items = items;
  };

  /** tableStatusが変更された時にセルの色を変更する。null値が入力された場合はitemの現在値を入力する */
  const statusChange = (e, item, fieldName: string) => {
    const target: HTMLInputElement = e.target;
    const pk: number = item[primaryKey];
    if (!Object.keys(tableStatus).includes(`${pk}`))
      tableStatus[pk] = makeInitialTableStatusRow(bulkUpdateFields);
    if (target.value != '') {
      tableStatus[pk][fieldName] = {
        value: target.value,
        color: '#ffff66b2',
      };
    } else tableStatus[pk][fieldName].value = target.value;
    // console.log(tableStatus, items);
  };

  /** 編集可能なフィールドをtableConfigから収集する */
  const updateEditableFileds = (tableConfig: TypeTableConfig) => {
    if (primaryKey) {
      bulkUpdateFields = [];
      Object.entries(tableConfig).forEach(([k, v]) => {
        if (v.editable == true) bulkUpdateFields.push(k);
      });
      if (displayUpdateButton === undefined)
        displayUpdateButton = bulkUpdateFields?.length > 0;
    }
  };

  const rowDelete = (item) => {
    // console.log(item, items, tableStatus);
    const pk = item[primaryKey];
    items = items.filter((el) => {
      console.log(el);
      return el[primaryKey] != item[primaryKey];
    });
    delete tableStatus[item[primaryKey]];
    tableStatus = tableStatus;
  };

  /** CSVfile Validation*/
  const csvValidation = (files: FileList): StandardResult => {
    let results = [];
    let error = '';
    for (let i = 0; i < files.length; i++) {
      results.push(files[i].type == 'text/csv');
    }

    const success = !results.includes(false);
    if (!success) {
      error = 'CSV形式ではないファイルが選択されています。';
    }
    return {
      success: success,
      message: error,
    };
  };

  let processing = false;

  // 検索窓にテキストが入力された場合の挙動
  const doSeach = () => {
    // 検索処理が行われている間は入力を無視する
    if (processing) {
      return;
    }
    processing = true;
    const tableFields = Object.keys(tableConfig);
    if (searchText.length) {
      searchResult = items.filter((item) => {
        let results: boolean[] = [];

        Object.entries(item).forEach(([fieldName, val]) => {
          if (
            tableFields.includes(fieldName) &&
            tableConfig[fieldName].choices
          ) {
            const choices = tableConfig[fieldName].choices;
            let result = false;
            if (choices) {
              // console.log(choices);
              const found = choices.find((choice) =>
                choice.display_name.includes(searchText),
              );
              if (found && found.value === val) result = true;
            }
            results.push(result);
          } else if (typeof val === 'string') {
            results.push(val.includes(searchText));
          } else if (typeof val === 'number') {
            results.push(String(val) == searchText);
          }
        });
        return results.includes(true);
      });
    } else {
      searchResult = items;
    }
    currentPage = 0;
    processing = false;
    // console.log('search');
  };

  $: if (items) doSeach();
  $: updateEditableFileds(tableConfig);
  $: lastPage = Math.max(Math.ceil(searchResult.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, searchResult.length);
  $: {
    slice = searchResult.slice(start, end);
  }

  // $: console.log(bulkUpdateItems);
  // $: console.log('tableStatus', tableStatus);
  // $: console.log('items', slice, tableConfig);
  // console.log('choices: ', tableConfig.category?.choices);
  // $: console.log('tableConfig', tableConfig, tableConfig.category?.choices);
</script>

<svelte:head>
  <style>
    .mdc-data-table__table {
      white-space: unset;
    }
  </style>
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<Spinner display={spinnerDisplay} />
<h5 class="font-weight-bold text-muted">{tableName}</h5>
<div class="row">
  <div class="d-flex align-items-center mb-2 col-3 col-sm-3">
    {#if displayUpdateButton}
      <Button
        color="secondary"
        class="font-weight-bold text-nowrap"
        variant="raised"
        on:click={bulkUpdateClick}>更新</Button
      >
    {/if}
  </div>
  <div class="d-flex align-items-center mb-2 col-8 col-sm-4">
    {#if csvDownload || csvUpload}
      <div style="min-width: 100px;">
        <Button on:click={() => csvMenu.setOpen(true)}>
          <Icon class="material-icons">menu</Icon>
          <Label>CSV</Label>
        </Button>
        <Menu bind:this={csvMenu}>
          <List>
            {#if csvDownload}
              <Item
                on:SMUI:action={(e) => {
                  downloadCSV(
                    csvName,
                    items,
                    csvFields,
                    tableConfig,
                    choiceDisplayMap,
                  );
                }}
              >
                <Text>csvエクスポート</Text>
              </Item>
            {/if}
            {#if csvUpload}
              <Item
                on:SMUI:action={(e) => {
                  clickFileInput();
                }}
              >
                <Text>csvインポート</Text>
              </Item>
              <FileInput
                acceptFile=".csv"
                bind:clickInput={clickFileInput}
                customValidation={csvValidation}
              />
            {/if}
          </List>
        </Menu>
      </div>
    {/if}
  </div>
  <div
    class="d-flex align-items-center mb-2 justify-content-end col-12 col-sm-5"
  >
    {#if searchable}
      <div class="form-floating mb-3 w-100">
        <input
          bind:value={searchText}
          on:input={doSeach}
          type="text"
          class="form-control w-100"
          placeholder="Search"
        />
        <label class="text-muted" for="floatingInput">検索</label>
      </div>
    {/if}
  </div>
</div>
<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:SMUIDataTable:sorted={handleSort}
  style="width: 100%;"
  class="editable-datatable"
  container$class="editable-datatable-container"
  table$class=""
>
  {#if displayTableHeader}
    <Head>
      <Row>
        <!--
        Note: whatever you supply to "columnId" is
        appended with "-status-label" and used as an ID
        for the hidden label that describes the sort
        status to screen readers.

        You can localize those labels with the
        "sortAscendingAriaLabel" and
        "sortDescendingAriaLabel" props on the DataTable.
      -->
        {#each Object.keys(tableConfig) as key}
          {#if !tableConfig[key].hidden}
            {#if items[0] && typeof items[0][key] === 'number'}
              <Cell numeric columnId={key} class="px-0 text-nowrap text-center">
                <!-- For numeric columns, icon comes first. -->
                <span>{tableConfig[key].label}</span>
                <IconButton size="button" class="material-icons"
                  >{arrow_direction}</IconButton
                >
              </Cell>
            {:else}
              <Cell columnId={key} class="px-0 text-nowrap text-center">
                <!-- For numeric columns, icon comes first. -->
                <span>{tableConfig[key].label}</span>
                <IconButton size="button" class="material-icons"
                  >{arrow_direction}</IconButton
                >
              </Cell>
            {/if}
          {/if}
        {/each}
        {#if rowDeletable}
          <Cell style="white-space: nowrap;">削除</Cell>
        {/if}
      </Row>
    </Head>
  {/if}
  <Body>
    {#each slice as item}
      <Row
        on:click={() => trClick(item)}
        style={coloredRow.includes(item) ? `background: ${rowColor};` : ''}
      >
        {#each Object.entries(tableConfig) as [key, configVal]}
          {#if !configVal.hidden}
            {#if configVal.type == 'image'}
              <Cell>
                {#if item[key]}
                  <img src={item[key]} alt="thumbnail" />
                {:else}
                  No Image
                {/if}
              </Cell>
            {:else if configVal.type == 'link_text'}
              <Cell style={configVal.style}>
                <a href={item.detail_url}>{item[key] || ''}</a>
              </Cell>
            {:else if configVal.type == 'color'}
              <Cell>
                <div style="background: {item[key]};">
                  {item[key]}
                </div>
              </Cell>
            {:else if configVal.type == 'person_name'}
              <Cell style="white-space: nowrap;">
                <span class="furigana">{item['get_full_furigana']}</span><br />
                <span class="full_name">{item[key]}</span>
              </Cell>
            {:else if configVal.type == 'date'}
              <Cell>
                {item[key] ? dateToStr(new Date(item[key])).YMDJPformat : ''}
              </Cell>
            {:else if configVal.type == 'datetime'}
              <Cell>
                {item[key] ? dateToStr(new Date(item[key])).YMDhmJPformat : ''}
              </Cell>
            {:else if configVal.type == 'time'}
              <Cell>
                {item[key] ? dateToStr(new Date(item[key])).hmformat : ''}
              </Cell>
            {:else if configVal.type == 'amount_changed'}
              <Cell numeric style="padding-right: 1.5rem;">
                {#if item.slip_type == 'in'}
                  {`+${item[key].toLocaleString()}`}
                {:else if item.slip_type == 'out'}
                  {`${item[key].toLocaleString()}`}
                {:else if ['keep', 'wait'].includes(item.slip_type)}
                  {`${item[key].toLocaleString()}`}
                {:else}
                  {item[key] > 0
                    ? `+${item[key].toLocaleString()}`
                    : `${item[key].toLocaleString()}`}
                {/if}
              </Cell>
            {:else if ['object', 'field'].includes(configVal.type)}
              <Cell>
                {makeObjectStr(item[key], configVal.objectKey)}
              </Cell>
            {:else if configVal.type === 'choices'}
              <Cell>
                {getChoiceDisplay(choiceDisplayMap, key, item[key])}
              </Cell>
            {:else if configVal.type === 'float'}
              <Cell
                numeric
                class="text-right"
                style="padding-right: 1.5rem; {tableStatus[item[primaryKey]] &&
                tableStatus[item[primaryKey]][key]
                  ? `background-color: ${
                      tableStatus[item[primaryKey]][key].color
                    }`
                  : ''};"
              >
                {#if configVal.editable}
                  <input
                    bind:value={item[key]}
                    on:change={(e) => statusChange(e, item, key)}
                    type="number"
                    step="0.1"
                    min="0"
                    class="table-input-number"
                  />
                {:else}
                  {item[key] != null && isFinite(item[key])
                    ? item[key].toLocaleString()
                    : ''}
                {/if}
              </Cell>
            {:else if configVal.type === 'number'}
              <Cell
                numeric
                class="text-right"
                style="padding-right: 1.5rem; {tableStatus[item[primaryKey]] &&
                tableStatus[item[primaryKey]][key]
                  ? `background-color: ${
                      tableStatus[item[primaryKey]][key].color
                    }`
                  : ''};"
              >
                {#if configVal.editable}
                  <input
                    bind:value={item[key]}
                    on:change={(e) => statusChange(e, item, key)}
                    class="table-input-number"
                    style={tableConfig[key].inputStyle}
                    type="number"
                    step="1"
                    min="0"
                  />
                {:else}
                  {isFinite(item[key]) ? item[key].toLocaleString() : ''}
                {/if}
              </Cell>
            {:else if configVal.type === 'compare_num'}
              <Cell
                numeric
                style="padding-right: 1.5rem; background-color:{tableStatus[
                  item[primaryKey]
                ]
                  ? tableStatus[item[primaryKey]][key].color
                  : ''};"
              >
                {#if configVal.editable}
                  <div style="padding-right: 1.2rem">{item[key]}</div>
                  <input
                    value={item[key]}
                    on:change={(e) => statusChange(e, item, key)}
                    class="table-input-text"
                    style="text-align:right; max-width: 5rem; {tableConfig[key]
                      .inputStyle};"
                    type="number"
                    step="1"
                    min="0"
                  />
                {/if}
              </Cell>
            {:else}
              <Cell
                style={`background-color:${
                  tableStatus[item[primaryKey]]
                    ? tableStatus[item[primaryKey]][key].color
                    : ''
                }`}
              >
                {#if configVal.editable}
                  <input
                    bind:value={item[key]}
                    on:change={(e) => statusChange(e, item, key)}
                    class="table-input-text"
                    style={tableConfig[key].inputStyle}
                  />
                {:else}
                  {item[key] ? item[key] : ''}
                {/if}
              </Cell>
            {/if}
          {/if}
        {/each}
        {#if rowDeletable}
          <Cell class="text-center">
            <button
              class="icon-button d-flex justify-content-center align-items-center"
            >
              <Icon
                style="font-size:1rem;"
                class="material-icons"
                on:click={() => rowDelete(item)}
              >
                close
              </Icon>
            </button>
          </Cell>
        {/if}
      </Row>
    {/each}
    <slot />
  </Body>

  <Pagination
    slot="paginate"
    style={!displayPagination ? 'display: none;' : ''}
  >
    <svelte:fragment slot="rowsPerPage">
      <Label>Rows Per Page</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        {#each rowsSelect as select}
          <Option value={select}>{select}</Option>
        {/each}
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      <div>
        {start + 1}-{end} of {searchResult.length}
      </div>
    </svelte:fragment>
    <div>
      <IconButton
        class="material-icons"
        action="first-page"
        title="First page"
        style="width: 21%;"
        on:click={() => (currentPage = 0)}
        disabled={currentPage === 0}>first_page</IconButton
      >
      <IconButton
        class="material-icons"
        action="prev-page"
        title="Prev page"
        style="width: 21%;"
        on:click={() => currentPage--}
        disabled={currentPage === 0}>chevron_left</IconButton
      >
      <IconButton
        class="material-icons"
        action="next-page"
        title="Next page"
        style="width: 21%;"
        on:click={() => currentPage++}
        disabled={currentPage === lastPage}>chevron_right</IconButton
      >
      <IconButton
        class="material-icons"
        action="last-page"
        title="Last page"
        style="width: 21%;"
        on:click={() => (currentPage = lastPage)}
        disabled={currentPage === lastPage}>last_page</IconButton
      >
    </div>
  </Pagination>
</DataTable>

<style>
  :global(.editable-datatable-container::-webkit-scrollbar) {
    height: 0.8rem;
  }
  :global(.editable-datatable-container::-webkit-scrollbar-thumb) {
    background: #bcbcbc;
  }
  img {
    max-height: 50px;
    padding: 2px;
  }
  .furigana {
    font-size: x-small;
  }
  .table-input-text {
    padding: 0.1rem 0.5rem;
    border: 0.02rem solid #ccc;
    border-radius: 0.25rem;
  }
  .table-input-number {
    padding: 0.1rem 0.5rem;
    border: 0.02rem solid #ccc;
    border-radius: 0.25rem;
    text-align: right;
  }
  .icon-button {
    background-color: #ddd;
    color: #555;
    border: none;
    border-radius: 1rem;
  }
</style>
