<script lang="ts">
  export let color: string = '#cfcfcf';
  export let display: boolean = false;

  const changeDisplay = () => {
    display = !display;
  };
</script>

<button
  on:click={changeDisplay}
  class="font-weight-bold text-muted"
  style="width:100%; border:0; cursor: pointer; padding: 0rem; background-color: {color}"
>
  <div class="panel-label">
    <slot />
  </div>
</button>

<style>
  .panel-label {
    padding: 0.5rem;
  }
</style>
