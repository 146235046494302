import type { PageCondition, TypeTableConfig } from "scripts/utils/types";
import { readable, writable, type Readable, type Writable } from "svelte/store";
import type { TypeSlipItem } from "../types";
import { dateToStr } from "scripts/utils/date_utils";
import { storable } from "scripts/utils/commonStates";

export type SlipTypeKey = 'in' | 'out' | 'wait' | 'keep'
export type SlipTypeVal = '入庫' | '出庫' | '入荷待ち' | '取り置き'
type SlipTypeChoice = Record<SlipTypeKey, SlipTypeVal>
type SlipTypeChoiceInverse = Record<SlipTypeVal, SlipTypeKey>

export const slipTypeChoice: Readable<SlipTypeChoice> = readable({
    'in': '入庫',
    'out': '出庫',
    'wait': '入荷待ち',
    'keep': '取り置き'
})

export const slipTypeChoiceInverse: Readable<SlipTypeChoiceInverse> = readable({
    '入庫': 'in',
    '出庫': 'out',
    '入荷待ち': 'wait',
    '取り置き': 'keep'
})

export const purchasesPageCondition = storable({
    activeTab: '入庫',
    searchParamObj: {}
}, 'purchases')

export const INITIAL_SLIP_ITEM: Readable<TypeSlipItem> = readable({
    stock: null,
    name: "",
    cost: 0,
    unit: "個",
    amount_changed: 0,
    value_changed: 0,
    change_reason: "",
    date: dateToStr(new Date()).iso8601Format,
    slip: null

})

export const slipTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'string', editable: false, hidden: true },
    number: {
        label: '番号',
        type: 'number',
        editable: false,
        style: 'text-align: center;',
    },
    date_created: {
        label: '作成日',
        type: 'date',
        editable: false,
    },
    order: {
        label: '売上',
        type: 'choices',
        editable: false,
        choices: [],
        hidden: true,
    },
    slipitem_set: {
        label: '内訳',
        type: 'field',
        editable: false,
        objectKey: ['name', 'amount_changed']
    },
    type: {
        label: '種類',
        type: 'choices',
        editable: false,
        choices: [],
    },
})

export const slipItemTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'number', editable: false, hidden: true },
    name: {
        label: '名称',
        type: 'string',
        editable: true,
        inputStyle: 'min-width:18rem;  width: 100%;'
    },
    cost: {
        label: '原価',
        type: 'number',
        editable: true,
        inputStyle: 'width:7rem;  width: 100%;'
    },
    amount_changed: {
        label: '数量',
        type: 'float',
        editable: false,
        inputStyle: 'width:7rem;  width: 100%;'
    },
    unit: {
        label: '単位',
        type: 'string',
        editable: true,
        inputStyle: 'max-width:4rem;  width: 100%;'
    },
    value_changed: {
        label: '金額',
        type: 'number',
        editable: false,
        style: 'min-width:8rem;'
    }
})

export const stockTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'number', editable: false, hidden: true },
    thumbnail: { label: '画像', type: 'image', editable: false, hidden: true },
    code: { label: '商品コード', type: 'string', editable: false },
    stock_type: { label: '取扱区分', type: 'choices', editable: false, hidden: true },
    name: {
        label: '名称',
        type: 'string',
        editable: false,
    },
    cost: {
        label: '原価',
        type: 'number',
        editable: false,
    },
    supplier: {
        label: '業者',
        type: 'choices',
        editable: false,
        hidden: true,
    },
    unit: {
        label: '単位',
        type: 'string',
        editable: false,
        hidden: true,
    },
})

export const purchaseOrderTableConfig: Writable<TypeTableConfig> = writable({
    pk: { label: 'ID', type: 'number', editable: false, hidden: true },
    ordered_date: { label: '発注日', type: 'datetime', editable: false },
    slip_number: { label: '伝票番号', type: 'string', editable: false },
    company_name: { label: '取引先', type: 'string', editable: false },
})