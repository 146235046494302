<script lang="ts">
  import 'static/styles/print_page.css/';
  import {
    routeAccounts,
    routePurchasesApp,
    routeStockerApp,
  } from 'scripts/router';
  import { fetchCustomRequest, fetchGetRequest } from 'scripts/utils/fetcher';
  import { onMount } from 'svelte';
  import type {
    TypePurchaseOrder,
    TypeSlipDetail,
  } from 'scripts/purchase/types';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type { TypeUser } from 'scripts/accounts/types';
  import { downloadBlob, linebreakToBr } from 'scripts/utils/utils';
  import Spinner from 'scripts/global_components/child_components/Spinner.svelte';
  import { navigate } from 'svelte-routing';

  export let params;

  let slip: TypeSlipDetail;
  let user: TypeUser;
  let spinnerDisplay = false;

  let purchaseOrder: TypePurchaseOrder;

  let totalPrice = 0;

  const getPriceTotal = (purchaseOrder: TypePurchaseOrder) => {
    let priceTotal = 0;
    if (purchaseOrder) {
      purchaseOrder?.inout_slip_display?.slipitem_set.forEach((slipItem) => {
        priceTotal += slipItem.value_changed;
      });
    }
    totalPrice = priceTotal;
  };

  onMount(() => {
    makeDatasets();
  });

  const makeDatasets = async () => {
    const puchaseOrderEndpoint = routePurchasesApp.purchaseOrderSlipApi(
      params.pk,
    );
    const res = await fetchGetRequest({
      endpoint: puchaseOrderEndpoint,
      errorMessage: 'OrderSheetの取得に失敗しました。',
    });
    if (res.success) {
      purchaseOrder = res.data;
    } else {
      alert(res.error);
    }

    const userRes = await fetchGetRequest({
      endpoint: routeAccounts.userApi(),
      errorMessage: 'Userの取得に失敗しました。',
    });
    if (userRes.success) {
      user = userRes.data;
      // console.log(user);
    } else {
      alert(userRes.error);
    }
  };

  const pdfDownload = async () => {
    spinnerDisplay = true;
    const content = document.querySelector('.print_area_shell').innerHTML;
    const downloadPdfEp = routePurchasesApp.downloadOrderSheet(
      purchaseOrder.pk,
    );
    const res = await fetchCustomRequest({
      endpoint: downloadPdfEp,
      method: 'POST',
      fetchObj: { content: content },
    });
    if (res.success) downloadBlob(res.success, 'purchaseOrder.pdf');
    else alert(res.error);
    if (res) spinnerDisplay = false;
  };

  const checkUser = (user: TypeUser, purchaseOrder: TypePurchaseOrder) => {
    if (user && purchaseOrder && user.pk) {
      if (user.company.uuid != purchaseOrder.from_user_display.company.uuid) {
        alert('userが所属する会社が一致しません。');
        navigate('/purchases/');
      }
    }
  };

  $: checkUser(user, purchaseOrder);
  $: getPriceTotal(purchaseOrder);
  $: console.log(purchaseOrder);
</script>

{#if purchaseOrder && user}
  <div class="print_area_shell mt-4">
    <section class="print_pages">
      <div class="row">
        <div class="col-11 mx-auto">
          <div class="d-flex justify-content-between">
            <h1>発注書</h1>
            <dl id="informations">
              <dt>伝票番号</dt>
              <dd>{purchaseOrder.slip_number}</dd>
              <br />
              <dt>日付</dt>
              <dd>
                {dateToStr(new Date(purchaseOrder.ordered_date)).YMDJPformat}
              </dd>
            </dl>
          </div>
          <div class="d-flex justify-content-between my-3" id="address-shell">
            <address id="to">
              <div class="to-name">
                {#if !purchaseOrder.name}
                  <div>{purchaseOrder.company_name} 御中</div>
                {:else}
                  <div>
                    {purchaseOrder.company_name}<br />{purchaseOrder.name} 様
                  </div>
                {/if}
              </div>
              <div class="to-address">
                <div>{purchaseOrder.address1}</div>
                {#if purchaseOrder.address2}
                  <div>{purchaseOrder.address2}</div>
                {/if}
              </div>
            </address>

            <address id="from">
              <div>
                {user.company.name}
              </div>
              <div>
                〒 {user.company.address.postal_code}<br />
                {user.company.address.address1}
                {#if user.company.address.address2}
                  {user.company.address.address2}
                {/if}
              </div>
            </address>
          </div>
        </div>
      </div>
      <div class="row payment-summary">
        <div class="col-11 mx-auto text-right">
          <div class="row payment-amount d-flex justify-content-end">
            <div class="col-4 col-md-2 py-2 border text-center text-nowrap">
              発注金額
            </div>
            <div class="col-5 py-2 border border-left-0 text-end pe-3">
              ¥ {totalPrice.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11 mx-auto">
          <table id="details">
            <thead>
              <tr>
                <th>名称</th>
                <th>単価</th>
                <th>数量</th>
                <th>小計</th>
              </tr>
            </thead>
            <tbody>
              {#if purchaseOrder.inout_slip_display?.slipitem_set.length}
                {#each purchaseOrder.inout_slip_display.slipitem_set as detail}
                  <tr class="content-row">
                    <td>{detail.name}</td>
                    <td>
                      {detail.cost.toLocaleString()}
                    </td>
                    <td class="amount-string">
                      {detail.amount_changed.toLocaleString()}
                    </td>
                    <td>{detail.value_changed.toLocaleString()}</td>
                  </tr>
                {/each}
              {/if}
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-11 mx-auto d-flex justify-content-end">
          <table id="total">
            <tbody>
              <tr class="total-price">
                <th>合計</th>
                <td>¥ {totalPrice.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-11 mx-auto">
          <p class="subject">備考：</p>
          <div class="note-area">
            <p class="p-2">
              {@html linebreakToBr(purchaseOrder.note) || ''}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="my-4 d-flex justify-content-center">
    <button class="btn btn-primary mx-2" on:click={() => pdfDownload()}>
      Download
    </button>
    <button
      class="btn btn-success mx-2"
      on:click={() => {
        navigate(`/purchases/${purchaseOrder.inout_slip}`);
      }}
    >
      仕入伝票
    </button>
  </div>
{/if}
<Spinner display={spinnerDisplay} />
