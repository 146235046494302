<script lang="ts">
  import {
    defaultFieldConfig,
    defaultFieldOptions,
    generateColorsHex,
  } from 'scripts/utils/utils';
  import FadeCollapse from '../FadeCollapse.svelte';
  import InvalidMessage from './InvalidMessage.svelte';
  import type { FieldConfig } from 'scripts/utils/types';

  const total = 48;
  const colors = generateColorsHex(48, 0.7, 'hex').background;
  const cols = 12;
  const rows = total / cols;
  let colorTable = [];
  for (let i = 0; i < total; i += cols) {
    colorTable.push(colors.slice(i, i + 11));
  }

  export let config: FieldConfig = {
    ...defaultFieldConfig,
    options: {
      ...defaultFieldOptions,
      label: '表示色',
      max_length: 50,
    },
  };
  export let value = '';
  export let inputStyle = '';

  let invalidMessage = '';
  let inputComponent: HTMLInputElement;
  let expand = false;
  let focus = false;

  const validation = (value?) => {
    if (config.input) {
      config.input.setCustomValidity(config.customValidation(value));

      invalidMessage = config.input.validationMessage;
      config.is_valid = config.input.validity.valid;
    }

    // console.log(input?.validity, invalidMessage);
  };

  $: config.input = inputComponent;
  $: inputStyle = `background: ${value}; color: white;`;
  $: validation(value);
</script>

<div class={config.inputClass}>
  <div class="form-floating mt-1" style=" width:100%">
    <input
      type="text"
      class="form-control"
      placeholder={config.placeHolder || '#FFFF'}
      style={inputStyle}
      maxlength={config.options.max_length}
      required={config.options.required}
      readonly={config.options.read_only}
      bind:value
      bind:this={inputComponent}
      on:focusout={() => validation(value)}
      on:focusout={() => (focus = false)}
      on:focus={() => (focus = true)}
      on:focus={() => (expand = !expand)}
    />
    <label for="floatingInput">{config.options.label}</label>
  </div>
  <InvalidMessage
    message={invalidMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
  <FadeCollapse expand={focus}>
    <div class="p-1">
      <table class="mx-auto">
        {#each colorTable as colorArray}
          <tr>
            {#each colorArray as color}
              <td>
                <button
                  on:click={() => (value = color)}
                  class="palette"
                  style={`background: ${color}`}
                />
              </td>
            {/each}
          </tr>
        {/each}
      </table>
    </div>
  </FadeCollapse>
</div>

<style>
  td {
    padding: 0;
  }
  .palette {
    width: 1rem;
    height: 1rem;
    border: 1px white solid;
  }
  .palette:hover {
    border: 1px grey solid;
  }
</style>
