<script lang="ts">
  import { Cell } from '@smui/data-table';
  import { getChoiceDisplay } from 'scripts/utils/CsvExportImport';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type {
    ChoiceDisplayMap,
    TypeTableConfigVal,
  } from 'scripts/utils/types';
  import { makeObjectStr } from 'scripts/utils/utils';

  export let item: Record<string, any>;
  export let fieldName: string;
  export let configVal: TypeTableConfigVal;
  export let choiceDisplayMap: ChoiceDisplayMap = {};
</script>

{#if !configVal.hidden}
  {#if configVal.type == 'image'}
    <Cell>
      {#if item[fieldName]}
        <img src={item[fieldName]} alt="thumbnail" />
      {:else}
        No Image
      {/if}
    </Cell>
  {:else if configVal.type == 'link_text'}
    <Cell style={configVal.style}>
      <a href={item.detail_url}>{item[fieldName]}</a>
    </Cell>
  {:else if configVal.type == 'color'}
    <Cell>
      <div style="background: {item[fieldName]};">
        {item[fieldName]}
      </div>
    </Cell>
  {:else if configVal.type == 'person_name'}
    <Cell style="white-space: nowrap;">
      <span class="furigana">{item['get_full_furigana']}</span><br />
      <span class="full_name">{item[fieldName]}</span>
    </Cell>
  {:else if configVal.type == 'date'}
    <Cell>
      {item[fieldName] ? dateToStr(new Date(item[fieldName])).YMDJPformat : ''}
    </Cell>
  {:else if configVal.type == 'datetime'}
    <Cell>
      {item[fieldName]
        ? dateToStr(new Date(item[fieldName])).YMDhmJPformat
        : ''}
    </Cell>
  {:else if configVal.type == 'time'}
    <Cell>
      {item[fieldName] ? dateToStr(new Date(item[fieldName])).hmformat : ''}
    </Cell>
  {:else if configVal.type == 'amount_changed'}
    <Cell numeric style="padding-right: 1.5rem;">
      {item[fieldName] > 0
        ? `+${item[fieldName].toLocaleString()}`
        : `-${item[fieldName].toLocaleString()}`}
    </Cell>
  {:else if ['object', 'field'].includes(configVal.type)}
    <Cell>
      {makeObjectStr(item[fieldName], configVal.objectKey)}
    </Cell>
  {:else if configVal.type === 'choices'}
    <Cell>
      {getChoiceDisplay(choiceDisplayMap, fieldName, item[fieldName])}
    </Cell>
  {:else if configVal.type === 'float'}
    <Cell numeric>
      {item[fieldName].toLocaleString()}
    </Cell>
  {:else if configVal.type === 'number'}
    <Cell numeric>
      {item[fieldName] ? item[fieldName].toLocaleString() : ''}
    </Cell>
  {:else if configVal.type === 'compare_num'}
    <Cell numeric>
      {item[fieldName] ? item[fieldName].toLocaleString() : ''}
    </Cell>
  {:else}
    <Cell>
      {item[fieldName] ? item[fieldName] : ''}
    </Cell>
  {/if}
{/if}

<style>
  img {
    max-height: 50px;
    padding: 2px;
  }
  .furigana {
    font-size: x-small;
  }
</style>
