import { SvelteComponent } from "svelte";

import BooleanInput from "scripts/global_components/input/BooleanInput.svelte";
import ColorPicker from "scripts/global_components/input/ColorPicker.svelte";
import TextInput from "scripts/global_components/input/TextInput.svelte";
import NumberInput from "scripts/global_components/input/NumberInput.svelte";
import ChoiceInput from "scripts/global_components/input/ChoiceInput.svelte";
import SelectInput from "scripts/global_components/input/SelectInput.svelte";
import DateInput from "scripts/global_components/input/DateInput.svelte";
import TimeInput from "scripts/global_components/input/TimeInput.svelte";
import ToggleSwitch from "scripts/global_components/input/ToggleSwitch.svelte";
import PasswordInput from "scripts/global_components/input/PasswordInput.svelte";
import FileInput from "scripts/global_components/input/FileInput.svelte";
import DatetimeInput from "scripts/global_components/input/DatetimeInput.svelte";
import ImageInput from "scripts/global_components/input/ImageInput .svelte";

interface ComponentMap {
    [key: string]: typeof SvelteComponent;
}

export const fieldComponentMap: ComponentMap = {
    // optionsから取得可能なtypeのマッピング
    integer: NumberInput,
    float: NumberInput,
    string: TextInput,
    boolean: BooleanInput,
    slug: TextInput,
    choice: ChoiceInput,
    field: ChoiceInput,
    datetime: DatetimeInput,
    date: DateInput,
    // 以下はオリジナルのマッピング。formConfigで手入力で指定する必要あり
    color: ColorPicker,
    time: TimeInput,
    toggle: ToggleSwitch,
    password: PasswordInput,
    file: FileInput,
    'image upload': ImageInput,
};
