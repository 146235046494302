<script lang="ts">
  /** input.QrReaderを使用してQR値を読み込み、apiEndpointにリクエストを送り、
   * 商品情報からprimaryKeyのフィールドを取り出してdetailEndpointにリダイレクトする
   */
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import ModalShell from './ModalShell.svelte';
  import QrReader from './input/QrReader.svelte';
  import { searchParamToQueryParams } from 'scripts/utils/form_utils';
  import type { SearchParamObj } from 'scripts/utils/types';

  export let value = '';
  export let apiEndpoint;
  export let detailEndpoint;
  export let searchField = 'qr_value';
  export let primaryKey = 'id';
  let searchParamObj: SearchParamObj = {};
  let itemList;
  let displayModal = false;
  let videoDisplay = false;
  let spinnerDisplay = false;
  let startCamera;

  const toDetailPage = (item) => {
    const url = `${detailEndpoint}${item[primaryKey]}/`;
    location.href = url;
  };

  const doSearch = async (event) => {
    const qrValue = event.detail;
    if (qrValue) {
      searchParamObj = makeSearchParamObj(qrValue);
      const res = await fetchGetRequest({
        endpoint: apiEndpoint,
        errorMessage: 'データの取得に失敗しました。',
        params: searchParamToQueryParams(searchParamObj, [searchField]),
      });
      if (res.success) {
        itemList = res.data;
        spinnerDisplay = false;
      } else {
        itemList = [];
        spinnerDisplay = false;
      }
      const item = itemList.find((i) => i[searchField] === qrValue);
      if (item) {
        value = '';
        displayModal = false;
        toDetailPage(item);
      } else {
        noValueSequense(
          '該当するアイテムが見つかりませんでした。再度カメラを起動しますか？',
        );
      }
    } else
      noValueSequense('有効なQR値がありません。再度カメラを起動しますか？');
  };

  /** QRコードからSearchParamObjを作成する */
  const makeSearchParamObj = (qrValue) => {
    const val: SearchParamObj = qrValue
      ? {
          [searchField]: {
            type: 'exact',
            value: qrValue,
          },
        }
      : {};
    return val;
  };

  const noValueSequense = (message: string) => {
    spinnerDisplay = false;
    const answer = confirm(message);
    if (answer) {
      value = '';
      startCamera();
    } else {
      value = '';
      displayModal = false;
    }
  };

  // $: doSearch(value);
  $: if (!displayModal && videoDisplay) videoDisplay = false;
</script>

<ModalShell
  bind:open={displayModal}
  on:modalOpen={startCamera}
  title="QRコード検索"
  btnLabel="QR検索"
>
  <QrReader
    on:qrRead={doSearch}
    bind:value
    bind:videoDisplay
    bind:startCamera
  />
</ModalShell>
