<script lang="ts">
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import { defaultFieldConfig, defaultFieldOptions } from 'scripts/utils/utils';
  import { onMount } from 'svelte';
  import InvalidMessage from './InvalidMessage.svelte';

  export let value;
  export let config = {
    ...defaultFieldConfig,
    options: {
      ...defaultFieldOptions,
      type: 'boolean',
    },
  };
  export let disabled = false;

  let inputComponent;
  let invalidMessage = '';
  let focus = true;

  const validation = (value?) => {
    if (config.input) {
      config.input.setCustomValidity(config.customValidation(value));

      invalidMessage = config.input.validationMessage;
      config.is_valid = config.input.validity.valid;
    }

    // console.log(config, invalidMessage);
  };

  $: {
    config.input = inputComponent?.getElement().querySelector('input');
    validation(true);
  }
  $: validation(value);
</script>

<div class={config.inputClass}>
  <FormField>
    <Checkbox
      required={config.options.required}
      {disabled}
      readonly={config.options.read_only}
      bind:this={inputComponent}
      bind:checked={value}
      on:change={() => validation(value)}
      on:change={() => (focus = value)}
    />
    <span slot="label">{config.options.label}</span>
  </FormField>
  <InvalidMessage
    message={invalidMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
</div>
