export const pathArr = location.pathname.split('/').filter((val) => val !== '');
export const APP_NAME = pathArr[0];
export const VIEW_NAME = pathArr[1];

const routeApp = `${APP_NAME}`;

const routeMap = {
  stocker: { api: 'stock' },
  sales: { api: 'order' },
  purchases: { api: 'purchases' },
}

/** PathObj = {
 * path: string,
 *   ${child_name}: {
 *     path: string,
 *     ${child_name}: ...
 *   } 
 * } */
type PathObj = Record<string, string | object>
const makeChild = (parent: string, child: object | string) => {
  let new_obj: PathObj = { path: parent }

  if (typeof child == 'string') {
    Object.assign(new_obj, {
      path: parent,
      [child]: { path: `${parent}${child}/` }
    })
  } else {
    Object.entries(child).forEach(([k, v]) => {
      const path = parent ? `${parent}${k}/` : `${k}/`
      Object.assign(new_obj, {
        path: parent,
        [k]: makeChild(path, v)
      })
    })
  }
  return new_obj
}
export const route = makeChild('', routeMap)

// << Config >>
export const routeConfig = {
  neRedirect: '/ne-redirect/'
}

// << Accounts >>
export const routeAccounts = {
  userApi() { return '/accounts/api/user/' },
  businessPartnerApi(pk?: string) {
    return pk ? `/accounts/api/business-partner/${pk}/` : '/accounts/api/business-partner/'
  },
}

// << Sales >>
export const routeSalesApp = {
  home(pk?: string) {
    const endpoint = pk ? `/sales/${pk}/` : `/sales/`;
    return endpoint
  },
  orderApi(pk?: string) {
    const endpoint = pk ? `/sales/api/order/${pk}/` : `/sales/api/order/`;
    return endpoint
  },
  paymentMethodApi(pk?: string) {
    const endpoint = pk ? `/sales/api/payment-method/${pk}/` : `/sales/api/payment-method/`;
    return endpoint
  }
};

// << Products >>
export const routeProductManagerApp = {
  productsApi(pk?: string) {
    const endpoint = pk ? `/product-manager/api/item/${pk}/` : `/product-manager/api/item/`;
    return endpoint
  },
  itemChoiceApi(pk?: string) {
    const endpoint = pk ? `/product-manager/api/item_choice/${pk}/` : `/product-manager/api/item_choice/`;
    return endpoint
  },
  deleteItemChoice(itemCode: string, id: number) {
    return `/product-manager/delete-item-choice/${itemCode}/${id}/`
  },
  descriptionTemplate(pk: string, code: string) { return `/product-manager/description-template/${pk}/${code}` },
  downloadCsv: `/product-manager/download-csv/`,
};

// << Stocker >>
export const routeStockerApp = {
  stockDetailPage(pk?: string) {
    return pk ? `/stocker/detail/${pk}/` : `/stocker/detail/`
  },
  downloadStockLabel(code: string) {
    return `/stocker/download-stock-label/${code}/`
  },
  slipApi(pk?: string) {
    const endpoint = pk ? `/stocker/api/slip/${pk}/` : `/stocker/api/slip/`;
    return endpoint
  },
  stockApi(pk?: string) {
    const endpoint = pk ? `/stocker/api/stock/${pk}/` : `/stocker/api/stock/`;
    return endpoint
  },
  stockForStockerApi(pk?: string) {
    const endpoint = pk ? `/stocker/api/stock_for_stocker/${pk}/` : `/stocker/api/stock_for_stocker/`;
    return endpoint
  },
  slipItemApi(pk?: string) {
    const endpoint = pk ? `/stocker/api/slip_item/${pk}/` : '/stocker/api/slip_item/';
    return endpoint
  },
  stockCategoryApi(pk?: string) {
    const endpoint = pk ? `/stocker/api/stock_category/${pk}/` : '/stocker/api/stock_category/';
    return endpoint
  }
};

// << Purchases >>
export const routePurchasesApp = {
  downloadOrderSheet(pk: number) {
    const endpoint = `/purchases/download-order-sheet/${pk}/`;
    return endpoint
  },
  purchaseOrderSlipApi(pk?: number) {
    const endpoint = pk ? `/purchases/api/purchase-order-slip/${pk}/` : `/purchases/api/purchase-order-slip/`;
    return endpoint
  },
};

// console.log(route.stocker)

/*export const routResizedImageRegister = {
  code: pathArr[pathArr.length - 1],
  get path() {
    return `${routeApp}/resized-image-register/${this.code}/`;
  },
};

export const routeImageUploader = {
  get changeCustomerImageIndex() {
    return `${routeBackOffice}/carte-maker/change-image-index/`;
  },
  get deleteCustomerImage() {
    return `${routeBackOffice}/carte-maker/image-delete/`;
  },
};

export const routeBackOfficeApp = {
  get pricingTable() {
    return `${routeBackOffice}/pricing-table/`;
  },
};

export const routeCarteMakerApp = {
  get carteId() {
    return pathArr[pathArr.length - 1];
  },
  get carteNotes() {
    return `${routeBackOffice}/carte-maker/api/cartenotes`;
  },
  CustomerApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/customer/${pk}/` : `${routeBackOffice}/carte-maker/api/customer/`;
    return endpoint
  },
  CarteApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/carte/${pk}/` : `${routeBackOffice}/carte-maker/api/carte/`;
    return endpoint
  },
  CarteDetailContextApi() {
    return `${routeBackOffice}/carte-maker/api/carte-context/`;
  },
  OrderApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/order/${pk}/` : `${routeBackOffice}/carte-maker/api/order/`;
    return endpoint
  },
};
*/