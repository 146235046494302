<script lang="ts">
  import Checkbox from '@smui/checkbox';
  import { Cell, Row } from '@smui/data-table';
  import CustomCell from './CustomCell.svelte';
  import type { ChoiceDisplayMap, TypeTableConfig } from 'scripts/utils/types';
  import { createEventDispatcher } from 'svelte';
  import { Icon } from '@smui/icon-button';

  export let item: Record<string, any>;
  export let primaryKey: string;
  export let tableConfig: TypeTableConfig;
  export let selectedItems: Record<string, any>[];
  export let slice: Record<string, any>[];
  export let choiceDisplayMap: ChoiceDisplayMap = {};
  export let coloredRow: Record<string, any>[] = [];
  export let rowColor = '#e9e9e9';

  let checked = false;

  /** Checkboxが変化したらselectedItemsの中身を変更 */
  const changeSelected = (checked: boolean) => {
    if (checked) {
      selectedItems.push({ ...item });
      selectedItems = selectedItems;
    } else {
      selectedItems = selectedItems.filter(
        (el) => el[primaryKey] != item[primaryKey],
      );
    }
    // console.log(checked, selectedItems);
  };

  let currentItemIds = selectedItems.map((i) => i[primaryKey]);

  const changeSelectedItems = (slice: Record<string, any>[], selectedItems) => {
    currentItemIds = selectedItems.map((i) => i[primaryKey]);
    // console.log(currentItemIds);
    checked = selectedItems.includes(item);
    checked = currentItemIds.includes(item[primaryKey]);
  };

  const dispatch = createEventDispatcher();
  const trClick = (item) => {
    // console.log(item);
    dispatch('trClick', { item: item });
  };

  const onCheck = (e) => {
    // checked = false;
    // console.log(e.target.checked);
    dispatch('checked', { item: item, value: !checked });
  };

  // $: changeSelected(checked);
  // $: onCheck(checked);
  $: changeSelectedItems(slice, selectedItems);
</script>

<Row
  on:click={() => trClick(item)}
  style={coloredRow.includes(item) ? `background: ${rowColor};` : ''}
>
  <Cell>
    <div class="d-flex aligin-items-center justify-content-center">
      <button class="rowSelect" on:click={onCheck}>
        {#if checked}
          <Icon class="material-icons primary-color">check_box</Icon>
        {:else}
          <Icon class="material-icons text-muted">check_box_outline_blank</Icon>
        {/if}
      </button>
    </div>
  </Cell>
  {#each Object.entries(tableConfig) as [fieldName, configVal]}
    <CustomCell {item} {fieldName} {configVal} {choiceDisplayMap} />
  {/each}
</Row>

<style>
  .rowSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    background-color: white;
    max-width: 1rem;
  }
</style>
