<script lang="ts">
  // slot部分をモーダルウィンドウ化するコンポーネント
  import Dialog, { Title, Content } from '@smui/dialog';

  export let open = false;
  export let title = '';
  export let btnLabel = '新規部門登録';
  export let btnStyle =
    'border:none; border-radius:1rem; color:#555; background-color:#ddd';

  type AreaSize = 'small' | 'middle' | 'large';
  export let size: AreaSize = 'middle';

  let dialogWidth = '30';

  $: {
    if (size === 'small') {
      dialogWidth = '20';
    } else if (size === 'middle') {
      dialogWidth = '35';
    } else if (size === 'large') {
      dialogWidth = '50';
    }
  }
</script>

<div style="margin:0.5rem;">
  <button
    on:click={() => {
      open = !open;
    }}
    style={btnStyle}
  >
    {btnLabel}
  </button>
</div>
<Dialog
  bind:open
  aria-labelledby="title"
  aria-describedby="content"
  surface$style={`width: ${dialogWidth}rem; max-width: calc(100vw - 5rem);`}
>
  <Title style="text-align: center;" id="title">{title}</Title>
  <Content id="content">
    <slot />
  </Content>
</Dialog>
