<script lang="ts">
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { routePurchasesApp } from 'scripts/router';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { purchaseOrderTableConfig } from 'scripts/purchase/config/store';

  const enpoint = routePurchasesApp.purchaseOrderSlipApi();

  let purchaseOrderList = [];

  onMount(() => {
    makeDatasets();
  });

  const makeDatasets = async () => {
    const params = new URLSearchParams(location.search);
    const res = await fetchGetRequest({
      endpoint: enpoint,
      errorMessage: 'Slipの取得に失敗しました。',
    });
    if (res.success) {
      purchaseOrderList = res.data;
      // console.log(purchaseOrderList);
    } else {
      alert(res.error);
    }
  };
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="row p-2">
  <div class="col-12">
    <EditableDataTable
      on:trClick={(e) =>
        navigate(`/purchases/order-sheet/${e.detail.item.pk}/`)}
      items={purchaseOrderList}
      bind:tableConfig={$purchaseOrderTableConfig}
      primaryKey="pk"
    />
  </div>
</div>
