<script lang="ts">
  import Dialog, { Title, Content } from '@smui/dialog';
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { routeSalesApp, routeStockerApp } from 'scripts/router';
  import { dateToStr } from 'scripts/utils/date_utils';
  import {
    INITIAL_SLIP_ITEM,
    slipItemTableConfig,
    slipTypeChoice,
    stockTableConfig,
  } from 'scripts/purchase/config/store';
  import type {
    TypeSlipDetail,
    TypeSlipItem,
    TypeStock,
  } from 'scripts/purchase/types';
  import type {
    ChoiceDisplayMap,
    FormConfig,
    ResultWithData,
    SimpleFormConfig,
    TableStatus,
  } from 'scripts/utils/types';
  import { navigate } from 'svelte-routing';
  import SelectableDataTable from 'scripts/global_components/TableItem/SelectableDataTable.svelte';
  import ModalWithSmallBtn from 'scripts/global_components/ModalWithSmallBtn.svelte';
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import {
    getAndAppendOptions,
    makeConfigFromOptions,
  } from 'scripts/utils/form_utils';
  import { onMount } from 'svelte';
  import ModelDetailForm from 'scripts/global_components/ModelDetailForm.svelte';
  import { getSlipTotal } from 'scripts/utils/price_utils';
  import Spinner from 'scripts/global_components/child_components/Spinner.svelte';

  const slipEndpoint = routeStockerApp.slipApi();
  // テーブルの最大行数
  const maxRows = 18;
  /** makeDatasetsで使用 */
  export let slipPk;
  export let slip: TypeSlipDetail;
  // 伝票種類の初期値
  export let initialSlipType: string;
  // let items: TypeSlipItem[] = [];
  let slipItemTableStatus: TableStatus = {};
  let totalPrice = 0;
  let spinnerDisplay = false;
  let alertMessage = [];
  let stockChoiceDisplay = false;
  /** 在庫のマスタ */
  let stockList: TypeStock[] = [];
  /** 伝票に記載するためにチェックを入れた在庫 */
  let selectedStocks: TypeStock[] = [];
  /** slip.slip_itemsetになる */
  export let slipItemSet: TypeSlipItem[] = [];
  // 一時的に付与するslip.slipitem_setのID。postする際にはこのpkフィールドを削除してアップする。
  /** 伝票の内訳変更ができるか */
  export let slipItemEditable = true;

  const initialSlip: TypeSlipDetail = {
    pk: null,
    number: null,
    date_created: `${dateToStr(new Date()).iso8601Format}`,
    order: null,
    slipitem_set: [],
    type: undefined,
    purchase_order: null,
  };

  // 数量の変更を許可
  $slipItemTableConfig.amount_changed = {
    ...$slipItemTableConfig.amount_changed,
    editable: true,
  };

  let simpleSlipFormConfig: SimpleFormConfig = {
    Row1: {
      number: { inputClass: 'col-12 col-md-6' },
    },
    Row2: {
      type: { initialValue: '', inputClass: 'col-12 col-md-6' },
      date_created: { inputClass: 'col-12 col-md-6' },
    },
  };
  let slipFormConfig: FormConfig;
  let slipChoicceDisplayMap: ChoiceDisplayMap;
  let open = false;

  const makeDatasets = async () => {
    spinnerDisplay = true;
    if (slipPk) {
      let fetchRes = await fetchGetRequest({
        endpoint: routeStockerApp.slipApi(slipPk),
        errorMessage: 'SlipDetailの取得に失敗しました。',
      });

      if (fetchRes.success) {
        slip = fetchRes.data;
        slip.date_created = dateToStr(new Date()).iso8601Format;
      } else alert(fetchRes.error);
    } else {
      slip = { ...initialSlip };
    }

    // slip.typ 'in', 'out'はformConfigを空にして編集不可に
    if (['in', 'out'].includes(slip.type)) {
      simpleSlipFormConfig = {};
    } else if (['keep', 'wait'].includes(slip.type)) {
      simpleSlipFormConfig = {
        Row1: {
          type: { inputClass: 'col-12 col-md-6' },
          date_created: { inputClass: 'col-12 col-md-6' },
        },
      };
    }

    const configs = await makeConfigFromOptions(
      slipEndpoint,
      simpleSlipFormConfig,
    );
    slipFormConfig = configs.formConfig;
    slipChoicceDisplayMap = configs.choiceDisplayMap;
    if (!slip.pk)
      slip.number = slipFormConfig.Row1.number.initialValue as number;

    const ep = routeStockerApp.stockApi();
    getAndAppendOptions(ep, {}, $stockTableConfig); // formconfigは必要ないので空のオブジェクトを入れる

    const res = await fetchGetRequest({
      endpoint: ep,
      errorMessage: 'Stockの取得に失敗しました。',
    });
    if (res.success) {
      stockList = res.data;
      initSlip(slip, stockList);
    } else {
      alert(res.error);
    }
    spinnerDisplay = false;
  };
  makeDatasets();

  // slipが初期化された時に１度だけ実行
  const initSlip = (slip: TypeSlipDetail, stockList: TypeStock[]) => {
    if (!initialSlipType && slip.pk) {
      initialSlipType = slip.type;

      if (!initialSlipType || ['keep', 'wait'].includes(slip.type)) {
        // 数量の変更を許可
        $slipItemTableConfig.amount_changed = {
          ...$slipItemTableConfig.amount_changed,
          editable: true,
        };
      } else {
        slipItemEditable = false;
      }
    }
    slipItemSet = slip.slipitem_set;
    const currentStockIds = slipItemSet.map((slipitem) => slipitem.stock);
    selectedStocks = stockList.filter((stock) =>
      currentStockIds.includes(stock.pk),
    );
  };

  const changeSelectedStocks = (selectedStocks: TypeStock[]) => {
    const selectedStockIds = selectedStocks.map((s) => s.pk);
    // チェックボックスが外され、selectedStocksから消えたものをslipitem_setから除く
    const newSlipItemSet = slipItemSet.filter((slipItem) =>
      selectedStockIds.includes(slipItem.stock),
    );

    // 現在slipitem_setにあるstockのpkを収集
    const currentStockIds = slipItemSet.map((slipitem) => slipitem.stock);
    selectedStocks.forEach((stock) => {
      if (!currentStockIds.includes(stock.pk)) {
        // tempolary_pk += 1;
        newSlipItemSet.push({
          ...$INITIAL_SLIP_ITEM,
          stock: stock.pk,
          name: stock.name,
          cost: stock.cost,
          unit: stock.unit,
          slip: slip.pk,
        });
      }
    });
    slipItemSet = newSlipItemSet;
    // console.log('sel', currentStockIds, slip);
  };

  const changeTableStatus = (slipItemTableStatus: TableStatus) => {
    slipItemSet.forEach((slipItem) => {
      const amount_changed = slipItem.amount_changed;
      const cost = slipItem.cost;
      slipItem.value_changed = Math.round(amount_changed * cost);
    });
    const currentStockIds = slipItemSet.map((i) => i.stock);
    selectedStocks = selectedStocks.filter((s) =>
      currentStockIds.includes(s.pk),
    );
    slipItemSet = slipItemSet;
  };

  // slip, tableStatusが変更された時にchange_valueの値を変更する
  const changeSlipItemSet = (slipItemSet: TypeSlipItem[]) => {
    // if (slipItemSet.length) console.log(slipItemSet[0].amount_changed);
    if (slip) slip.slipitem_set = slipItemSet;
    totalPrice = getSlipTotal(slipItemSet);
    // items = slip?.slipitem_set;
    // console.log(slip);
  };

  /** カスタムのフォームバリデーション ModelDetailFormの同名ファンクションをオーバーライド */
  const confirmFunction = (): ResultWithData => {
    let valid = false;
    let data = {};

    if (slip.slipitem_set.length <= 0) {
      data = {
        slipitem_set: [
          '伝票の内訳が登録されていません。一つ以上の入出庫を登録して下さい。',
        ],
      };
    } else {
      valid = true;
    }
    return {
      success: valid,
      data: data,
      error: [''],
    };
  };

  const onProcessEnd = (event) => {
    // console.log(event);
    if (event.detail.data.success && !slipPk)
      navigate(`/purchases/${event.detail.data.success.pk}/`);
    else location.reload();
  };

  $: changeSelectedStocks(selectedStocks);
  $: changeTableStatus(slipItemTableStatus);
  $: changeSlipItemSet(slipItemSet);

  // $: console.log(slip);
  // $: console.log(slipItemSet);
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="mt-2">
  <ModelDetailForm
    modelName="伝票"
    bind:formConfig={slipFormConfig}
    endpoint={slipEndpoint}
    bind:state={slip}
    primaryKey="pk"
    cancelButtonDisplay={false}
    {confirmFunction}
    on:processEnd={(event) => {
      onProcessEnd(event);
    }}
  >
    <hr />
    <h5 class="slip-bottom">内訳</h5>
    <div class="mt-3 mb-3 ps-3">
      <EditableDataTable
        bind:items={slipItemSet}
        bind:tableConfig={$slipItemTableConfig}
        bind:tableStatus={slipItemTableStatus}
        searchable={false}
        displayUpdateButton={false}
        displayPagination={false}
        primaryKey="stock"
        rowDeletable={slipItemEditable}
        rowsPerPage={maxRows}
      >
        {#if slipItemEditable}
          <tr>
            <td colspan="4">
              <div style="margin:0.5rem;">
                <button
                  on:click={() => {
                    open = !open;
                  }}
                  style="border:none; border-radius:1rem; color:#555; background-color:#ddd"
                >
                  +
                </button>
              </div>
              <!--ModalWithSmallBtn
                open={stockChoiceDisplay}
                btnLabel="+"
                size="large"
              >
                <SelectableDataTable
                  items={stockList}
                  bind:tableConfig={$stockTableConfig}
                  bind:selectedItems={selectedStocks}
                  primaryKey="pk"
                  {maxRows}
                />
              </ModalWithSmallBtn-->
            </td>
          </tr>
        {/if}
      </EditableDataTable>
      <div class="row">
        <div class="col-11 mx-auto d-flex justify-content-end">
          <table id="total">
            <tbody>
              <tr class="total-price">
                <th>合計</th>
                <td>¥ {totalPrice.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ModelDetailForm>
</div>
<Spinner bind:display={spinnerDisplay} />

<Dialog
  bind:open
  aria-labelledby="title"
  aria-describedby="content"
  surface$style={`width: 50rem; max-width: calc(100vw - 5rem);`}
>
  <Title style="text-align: center;" id="title" />
  <Content id="content">
    <SelectableDataTable
      items={stockList}
      bind:tableConfig={$stockTableConfig}
      bind:selectedItems={selectedStocks}
      primaryKey="pk"
      {maxRows}
    />
  </Content>
</Dialog>
