<script lang="ts">
  import type { FieldConfig } from 'scripts/utils/types';

  // export let labelDisplay: boolean = true;
  export let values;
  export let config: FieldConfig;
  let config_start;
  let config_end;

  $: config_start = { ...config };
  $: config_end = { ...config };
  // $: console.log(values);
</script>

{#if values && !config.options.read_only}
  <svelte:component
    this={config_start.component}
    bind:value={values[0]}
    bind:config={config_start}
  />
  <div class="col-1 px-0" style="text-align: center;">〜</div>
  <svelte:component
    this={config_end.component}
    bind:value={values[1]}
    bind:config={config_end}
  />
{/if}
