<script lang="ts">
  import { dateToStr } from 'scripts/utils/date_utils';
  import type { TypeSlipDetail } from './types';
  import { routeSalesApp } from 'scripts/router';
  import { slipTypeChoice } from './config/store';
  import SlipForm from './child/SlipForm.svelte';

  let slip: TypeSlipDetail = {
    pk: null,
    number: null,
    date_created: `${dateToStr(new Date()).iso8601Format}`,
    order: null,
    slipitem_set: [],
    type: undefined,
    purchase_order: null,
  };
  // 伝票種類の初期値
  let initialSlipType: string;
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

{#if slip}
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">入出庫伝票</div>
        <div class="card-body">
          {#if slip.pk}
            <div class="row">
              <div class="col-6">
                <div>伝票番号 : {slip.number || ''}</div>
              </div>
              <div class="col-6">
                {#if ['out', 'keep'].includes(initialSlipType)}
                  売上伝票 : <a href={routeSalesApp.home(`${slip.order}`)}
                    >{slip.order || ''}</a
                  >
                {:else}
                  発注書 : <a
                    href={`/purchases/order-sheet/${slip.purchase_order}/`}
                  >
                    {slip.purchase_order || ''}
                  </a>
                {/if}
              </div>
            </div>
          {/if}
          {#if ['in', 'out'].includes(initialSlipType)}
            <div class="row ps-3">
              <div class="col-6">
                <div>伝票種類 : {$slipTypeChoice[initialSlipType]}</div>
              </div>
              <div class="col-6">
                日付 : {dateToStr(new Date(slip.date_created)).YMDhmJPformat ||
                  ''}
              </div>
            </div>
          {/if}
          <SlipForm
            slipPk={undefined}
            bind:slip
            slipItemEditable={true}
            bind:initialSlipType
          />
        </div>
      </div>
    </div>
  </div>
{/if}
