<script lang="ts">
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { navigate } from 'svelte-routing';
  import { slipTableConfig } from 'scripts/purchase/config/store';
  import type { ChoiceDisplayMap } from 'scripts/utils/types';

  export let slipList = [];
  export let choiceDisplayMap: ChoiceDisplayMap = {};

  // $: console.log(slipList);
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="row p-2">
  <div class="col-12">
    <EditableDataTable
      on:trClick={(e) => navigate(`${e.detail.item.pk}`)}
      items={slipList}
      bind:tableConfig={$slipTableConfig}
      bind:choiceDisplayMap
      primaryKey="pk"
      csvDownload={true}
      csvFields={Object.keys($slipTableConfig)}
    />
  </div>
</div>
