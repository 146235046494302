<script lang="ts">
  import Textfield from '@smui/textfield';
  import type { FieldConfig } from 'scripts/utils/types';
  import { defaultFieldConfig, defaultFieldOptions } from 'scripts/utils/utils';
  import InvalidMessage from './InvalidMessage.svelte';

  export let value = 0;
  export let config: FieldConfig = {
    ...defaultFieldConfig,
    options: {
      ...defaultFieldOptions,
      type: 'integer',
      min_value: 0,
      max_value: 2147483647,
    },
  };
  export let step = 1;
  export let inputStyle = '';

  let inputComponent: HTMLInputElement;
  let invalidMessage = '';
  let focus = false;

  const setStep = (config: FieldConfig) => {
    if (config.options.type === 'integer') {
      step = 1;
    } else if (config.options.type === 'float') {
      step = 0.1;
    }
    // console.log(config.options, step);
  };

  const validation = (value?) => {
    if (config.input) {
      config.input.setCustomValidity(config.customValidation(value));

      invalidMessage = config.input.validationMessage;
      config.is_valid = config.input.validity.valid;
    }

    // console.log(input?.validity, invalidMessage);
  };

  $: config.input = inputComponent;
  $: setStep(config);
  $: validation(value);
</script>

<div class={config.inputClass}>
  <div class="input-group" style="width:100%">
    {#if config.label_display}
      <label for={`id_for_${config.name}`} class="input-group-text">
        {config.options.label}
      </label>
    {/if}
    <input
      type="number"
      id={`id_for_${config.name}`}
      class="form-control"
      placeholder={config.placeHolder || '123'}
      style={inputStyle}
      maxlength={config.options.max_length}
      required={config.options.required}
      readonly={config.options.read_only}
      min={config.options.min_value}
      max={config.options.max_value}
      {step}
      bind:value
      bind:this={inputComponent}
      on:focusout={() => validation(value)}
      on:focusout={() => (focus = false)}
      on:focus={() => (focus = true)}
    />
  </div>
  <InvalidMessage
    message={invalidMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
</div>
