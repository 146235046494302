<script lang="ts">
  /** インプット要素は不可視に設定されている。親コンポーネントでbuttonなどのエレメントを用意し、
   * 親コンポーネントのイベントとこのコンポーネントのclickInputをbindして使用する
   * 親コンポーネントの実装例：
   * let clickFileInput;
   * let csvFiles;
   * <Item
   *   on:SMUI:action={(e) => {
   *     clickFileInput();
   *   }}
   * >
   *   <Text>{csvButtonLabel[0]}</Text>
   * </Item>
   * <FileInput
   *   acceptFile=".csv"
   *   files={csvFiles}
   *   bind:clickInput={clickFileInput}
   * />
   *
   * このコンポーネントでは、readAsアトリビュートで指定された方法でファイルを読み込み、
   * string[], ArrayBuffer[]に変換するところまでを受け持つ
   */
  import { onMount } from 'svelte';
  import { loadedFiles } from 'scripts/utils/commonStates';
  import type { StandardResult } from 'scripts/utils/types';

  export let acceptFile;
  let inputElement: HTMLInputElement;
  let files: FileList;
  let maxSize = 5 * 1024 * 1024; // 5MB
  export let multiple = false;
  export const clickInput = () => {
    inputElement.click();
  };
  export let readAs = 'text';
  export let customValidation = (files: FileList): StandardResult => {
    return {
      success: true,
      message: '',
    };
  };

  const isValid = (files: FileList): StandardResult => {
    let size = 0;
    let errors = '';
    for (let i = 0; i < files.length; i++) {
      size += files[i].size;
    }
    let result = size < maxSize;
    if (!result)
      errors =
        'ファイルサイズの合計が展開できる最大値を超えています。複数のファイルに分割するか、同時に展開するファイルを少なくしてリトライしてください。\n';
    const customValidationResult = customValidation(files);
    const validationResult: StandardResult = {
      success: result && customValidationResult.success,
      message: (errors += customValidationResult.message),
    };

    return validationResult;
  };

  const loadFiles = async () => {
    files = inputElement.files;
    let loadResult: (ArrayBuffer | string)[] = [];
    const res = isValid(files);
    if (res.success) {
      for (let i = 0; i < files.length; i++) {
        if (readAs == 'text') loadResult.push(await files[i].text());
        else if (readAs == 'arrayBuffer')
          loadResult.push(await files[i].arrayBuffer());
      }
    } else alert(res.message);
    // console.log(loadResult);
    $loadedFiles = loadResult;
    inputElement.value = '';
  };

  onMount(() => {
    inputElement.addEventListener('change', loadFiles);
  });

  //$: addEvt(inputElement)
</script>

<input
  type="file"
  bind:this={inputElement}
  accept={acceptFile}
  {multiple}
  style="display: none"
/>
