import getCsrfToken from "scripts/get_csrf_token";
import type { DownloadCsvQuery, FetchResult, ResultWithData } from "./types";
import { routeProductManagerApp, routeSalesApp } from "scripts/router";
import type { ItemInfoObj } from "scripts/sales/types";
import { param } from "jquery";
import { makeFetchDataAndHeaders } from "./form_utils";


/** get リクエストのテンプレート。 methodは書き換え可能。optionsの取得にも使える*/
export const fetchGetRequest = async ({
    endpoint,
    errorMessage,
    method = 'GET',
    params = {},
}) => {
    const queryParams = new URLSearchParams(params)
    const endpoint_with_param = queryParams ? `${endpoint}?${queryParams}` : endpoint
    const res: ResultWithData = await fetch(endpoint_with_param, { method: method }).then(async (result) => {
        if (result.ok) {
            const dataObj = await result.json();
            return { success: true, data: dataObj, error: [] };
        } else {
            return { success: false, data: undefined, error: [errorMessage] };
        }
    }).catch(error => {
        return {
            success: false,
            data: null,
            error: [`${errorMessage}\n ${error}`]
        }
    })
    return res;
}

type FetchArg = {
    endpoint: string,
    method: RequestMode,
    errorMessage: string,
    fetchObj?: Record<string, any>
}
// POST, PUTなどcsrftokenが必要なリクエストのテンプレート
export const fetchCustomRequest = async ({
    endpoint,
    method,
    fetchObj = {}
}) => {
    const fetchData = makeFetchDataAndHeaders(fetchObj)
    // console.log(fetchData);

    const response: FetchResult = await fetch(endpoint, {
        method: method,
        mode: 'same-origin',
        headers: fetchData.headers,
        body: fetchData.body,
    }).then(async (result) => {
        if (result.ok) {
            const contentType = result.headers.get('content-type')
            console.log(contentType);
            const contentTypeArray = ['application/pdf', 'image/svg+xml'] // blobで受け付けるファイルタイプ
            if (contentTypeArray.includes(contentType)) {
                const blob = await result.blob()
                return { success: blob }
            } else if (contentType == 'application/json') {
                const data = await result.json();
                return { success: data };
            } else {
                return { error: { content_type: [`予期せぬファイルタイプのレスポンスが返却されました。 ${contentType}`] } }
            }
        } else {
            // const data = await result.json()

            const data = { status: [`${result.status}`] }
            return { success: undefined, error: data };
        }
    }).catch(error => {
        return {
            success: undefined,
            error: {
                common: [`エラーが発生したため、処理に失敗しました。\n ${error}`]
            }
        }
    });

    return response;
}

/** 汎用のOptions取得function  */
export const getOptions = async (endpoint: string, params = {}) => {
    return await fetchGetRequest({
        endpoint: endpoint,
        errorMessage: 'Optionsを取得できませんでした。',
        method: 'OPTIONS',
        params: params
    })
}

export const getItemList = async () => {
    const result = await fetchGetRequest(
        { endpoint: routeProductManagerApp.productsApi(), errorMessage: 'ItemListの取得に失敗しました。' }
    )
    if (result.success) {
        const item_lis: ItemInfoObj = result.data;
        return { success: item_lis };
    } else {
        return { error: '商品リストを取得できませんでした。' };
    }
};

export const getPaymentMethods = async (pk?: string) => {
    return await fetchGetRequest({
        endpoint: routeSalesApp.paymentMethodApi(pk),
        errorMessage: '支払い方法を取得できませんでした。',
    })
}


export const fetch_download_csv = (query: DownloadCsvQuery) => {
    return fetch(`/product-manager/download-csv/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
        },
        body: JSON.stringify(query),
    })
        .then((response) => response.blob())
        .then((blob) => {
            let anchor = document.createElement("a");
            anchor.download = "csv_file.zip";
            anchor.href = window.URL.createObjectURL(blob);
            anchor.click();
        });
}