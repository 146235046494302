import { getOptions } from "./fetcher";
import { fieldComponentMap } from "./field_component_map";
import type { FieldOptions, FormConfig, ResultWithData, TypeTableConfig, SimpleFormConfig, TypeAppendOptionsReturnVal, SearchParamObj, SearchParam } from "./types";
import { defaultFieldConfig, mergeFormConfig } from "./utils";


const getCsrfToken = () => {
  const csrftoken = document.querySelector<HTMLFormElement>('[name=csrfmiddlewaretoken]').value;
  return csrftoken;
};

export default getCsrfToken;


/** サーバーからoptionsを取得。 */
export const getModelOptions = async (endpoint: string, params = {}): Promise<ResultWithData> => {
  const res = await getOptions(endpoint, params);
  let postOptions: Record<string, FieldOptions> = {}
  let success: boolean
  let errorMessages: string[] = []
  if (res.success) {
    postOptions = res.data.actions.POST
      ? res.data.actions.POST
      : res.data.actions.PUT;
    // console.log(postOptions);
    success = true
  } else {
    success = false
    errorMessages.push(String(res.error))
  }
  return {
    success: success,
    data: postOptions,
    error: res.error
  }
};

/** 取得したOptionsをFormConfig, TableConfigに追加 choiceDisplayMapも作成*/
export const appendOptionsToConfig = (
  options: Record<string, FieldOptions>,
  formConfig: FormConfig = {},
  tableConfig: TypeTableConfig = {},
) => {
  Object.entries(options).forEach(([fieldName, option]) => {
    if (tableConfig[fieldName]) tableConfig[fieldName].choices = option.choices
    Object.values(formConfig).forEach((fieldConfig) => {
      if (fieldConfig[fieldName]) {
        if (option.help_text) fieldConfig[fieldName].helperText = option.help_text
        fieldConfig[fieldName].options = option
        fieldConfig[fieldName].component = fieldComponentMap[option.type]
        if (option.default) fieldConfig[fieldName].initialValue = option.default
        fieldConfig[fieldName].name = fieldName
      }
    })
  })
  /*Object.values(formConfig).forEach((fieldConfig) => {
    Object.entries(fieldConfig).forEach(([key, config]) => {
      if (options[key]) config.options = options[key];
      if (options[key].help_text)
        config.helperText = options[key].help_text;
      // console.log(key, tableConfig[key]);
      if (config.options.choices && tableConfig[key]) {
        tableConfig[key].choices = config.options.choices;
      }
    });
  });*/
  const choiceDisplayMap = makeChoiceDisplayMap(options)
  const searchParamObj: SearchParamObj = makeInitialSeachParamObj(formConfig)
  return {
    formConfig: formConfig,
    tableConfig: tableConfig,
    choiceDisplayMap: choiceDisplayMap,
    searchParamObj: searchParamObj,
    options: options,
  }
};

/** optionsを取得してformConfig, tableConfigに追加 */
export const getAndAppendOptions = async (endpoint: string, formConfig: FormConfig = {}, tableConfig: TypeTableConfig = {}, get_params = {}): Promise<ResultWithData> => {
  const optionRes = await getModelOptions(endpoint, get_params)
  let success = false
  let data = {
    formConfig: formConfig,
    tableConfig: tableConfig,
    choiceDisplayMap: {},
    searchParamObj: {},
    options: optionRes.data
  };
  let error = ''
  if (optionRes.success) {
    data = appendOptionsToConfig(optionRes.data, formConfig, tableConfig)
    success = true
  } else {
    error = 'optionsの取得に失敗しました'
  }
  return {
    success: success,
    data: data,
    error: [error]
  }
}

/** optionsが取得されたらfieldName, stockDetailの値でoptionsのdisplay_nameが取得できるmapを作成する */
export const makeChoiceDisplayMap = (options: Record<string, FieldOptions>) => {
  const choiceDisplayMap: Record<string, Record<string, string>> = {};
  Object.entries(options).forEach(([fieldName, optionVal]) => {
    if (optionVal.choices) {
      const valueDisplayMap = {};
      optionVal.choices.forEach((choice) => {
        valueDisplayMap[choice.value] = choice.display_name;
      });
      choiceDisplayMap[fieldName] = valueDisplayMap;
    }
  });
  return choiceDisplayMap
};

/** 必要項目のみを指定したsimpleFormConfigからdefaultFormConfigを作成 */
export const makeDefaultConfigFromOptions = (simpleFormConfig: SimpleFormConfig) => {
  // defaultConfigのみでFormConfigを作成
  let formConfig: FormConfig = {}

  Object.entries(simpleFormConfig).forEach(([rowStr, simpleConfigObj]) => {
    formConfig[rowStr] = {}
    Object.keys(simpleConfigObj).forEach(fieldName => {
      formConfig[rowStr][fieldName] = { ...defaultFieldConfig }
    })
  })
  return formConfig
}

/** 必要項目のみを指定したsimpleFormConfigからdefaultFormConfigを作成 */
export const makeDefaultConfigFromSimpleConfig = (simpleFormConfig: SimpleFormConfig) => {
  // defaultConfigのみでFormConfigを作成
  let formConfig: FormConfig = {}

  Object.entries(simpleFormConfig).forEach(([rowStr, simpleConfigObj]) => {
    formConfig[rowStr] = {}
    Object.keys(simpleConfigObj).forEach(fieldName => {
      formConfig[rowStr][fieldName] = { ...defaultFieldConfig }
    })
  })
  return formConfig
}

/** defaultConfigを作成し、getAndAppendOptionsを使用してoptionsの値を適用 */
/*export const makeConfigFromOptions = async (endpoint: string, simpleFormConfig: SimpleFormConfig, tableConfig: TypeTableConfig = {}, get_params = {}) => {

  let defaultConfig = makeDefaultConfigFromOptions(simpleFormConfig)

  let res = await getAndAppendOptions(endpoint, defaultConfig, tableConfig, get_params)
  let returnVal: TypeAppendOptionsReturnVal
  if (res.success) {
    returnVal = res.data
    let formConfig = returnVal.formConfig

    const childKey = ['options']

    // simpleFormConfigで設定した項目を反映
    Object.entries(formConfig).forEach(([rowStr, confObj]) => {
      Object.entries(confObj).forEach(([fieldName, config]) => {

        const simpleConfigVal = simpleFormConfig[rowStr][fieldName]
        // console.log(fieldName, config, simpleConfigVal);
        / FieldConfigValue内でネストされたオブジェクト形式になっている要素のKey
         * スプレッド構文では後に入力された値で上書きされてしまうので、別に取り出してスプレッド構文で合成結合しておく。
         /
        const childObj = {}
        childKey.forEach((key) => {
          let a = key in config ? config[key] : {}
          let b = key in simpleConfigVal ? simpleConfigVal[key] : {}
          const c = { ...a, ...b }
          if (Object.keys(c).length) childObj[key] = c
        })

        formConfig[rowStr][fieldName] = {
          ...config,
          ...simpleFormConfig[rowStr][fieldName],
          ...childObj
        }
      })
    })
    returnVal.formConfig = formConfig
    return returnVal
  } else {
    alert(res.error)
  }
}*/

/** defaultConfigを作成し、getAndAppendOptionsを使用してoptionsの値を適用 */
export const makeConfigFromOptions = async (endpoint: string, simpleFormConfig: SimpleFormConfig, tableConfig: TypeTableConfig = {}, get_params = {}) => {

  let defaultConfig = makeDefaultConfigFromSimpleConfig(simpleFormConfig)

  let res = await getAndAppendOptions(endpoint, defaultConfig, tableConfig, get_params)
  let returnVal: TypeAppendOptionsReturnVal
  if (res.success) {
    returnVal = res.data

    returnVal.formConfig = applySimpleFormConfigVal(returnVal.formConfig, simpleFormConfig)
    return returnVal
  } else {
    alert(res.error)
  }
}

/** simpleFormConfigで設定した値をformConfigに反映。
 * デフォルト値でFormConfigを作成し、これにサーバーから取得したoptionsの値を反映したのち、このファンクションを実行する
 */
export const applySimpleFormConfigVal = (formConfig: FormConfig, simpleFormConfig: SimpleFormConfig) => {
  const childKey = ['options']

  // simpleFormConfigで設定した項目を反映
  Object.entries(formConfig).forEach(([rowStr, confObj]) => {
    Object.entries(confObj).forEach(([fieldName, config]) => {

      const simpleConfigVal = simpleFormConfig[rowStr][fieldName]
      // console.log(fieldName, config, simpleConfigVal);
      /** FieldConfigValue内でネストされたオブジェクト形式になっている要素のKey
       * スプレッド構文では後に入力された値で上書きされてしまうので、別に取り出してスプレッド構文で合成結合しておく。
       */
      const childObj = {}
      childKey.forEach((key) => {
        let a = key in config ? config[key] : {}
        let b = key in simpleConfigVal ? simpleConfigVal[key] : {}
        const c = { ...a, ...b }
        if (Object.keys(c).length) childObj[key] = c
      })

      formConfig[rowStr][fieldName] = {
        ...config,
        ...simpleFormConfig[rowStr][fieldName],
        ...childObj
      }
    })
  })
  return formConfig
}

export const applyInitialValue = (formConfig: FormConfig, model: any) => {
  Object.values(formConfig).forEach((configs) => {
    Object.entries(configs).forEach(([key, config]) => {
      if (config.initialValue) {
        model[key] = config.initialValue
      }
    })
  })
}

/** FileListオブジェクトをbase64imageに変換 */
export const convertFileToBase64 = (files: FileList) => {
  const list = Array.from(files).map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      return reader.onload = (e) => resolve(e.target.result)
    })
  })
  return Promise.all(list).then((images) => {
    return images.length == 1 ? images[0] : images
  })
}

/** fetchCustomRequestで送信するデータをapplication/jsonにするか、multipartformにするかを判断し、データを作成する
 */
export const makeFetchDataAndHeaders = (fetchObj) => {
  const files = Object.values(fetchObj).filter((val) => val instanceof FileList && val.length)
  let bodyData
  let headerData
  let isIncludeFile = files.length ? true : false
  if (isIncludeFile) {
    bodyData = new FormData()
    // fetchObjにFileListが含まれる場合はFileReaderで変換
    Object.entries(fetchObj).forEach(([key, val]) => {
      if (val instanceof FileList && val.length) {
        Object.entries(val).forEach(([idx, file]) => {
          bodyData.append(key, file)
        });
      } else {
        bodyData.append(key, `${val}`)
      }
    })
    headerData = {
      'X-CSRFToken': getCsrfToken(),
    }
  } else {
    bodyData = fetchObj ? JSON.stringify(fetchObj) : undefined
    headerData = {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    }
  }
  return { body: bodyData, headers: headerData }
}


/** formConfigから商品の詳細検索用のQueryParamsの初期値を作成する。このQueryParamsはAdvancedSearchFormでユーザーによって入力値が反映される。
 * 入力されたQueryParamsはfetchGetファンクションのparamsに入れる */
const makeInitialSeachParamObj = (formConfig: FormConfig) => {
  let searchParamObj: SearchParamObj = {}
  Object.values(formConfig).forEach((confObj) => {
    Object.entries(confObj).forEach(([key, val]) => {
      let param: SearchParam = { type: 'contains', value: '' }
      if (['integer', 'float'].includes(val.options.type)) param = { type: 'range', value: [] }
      else if (['boolean', 'choice', 'field'].includes(val.options.type)) param = { type: 'exact', value: '' }
      else if (['date', 'datetime'].includes(val.options.type)) param = { type: 'range', value: [] }
      else param = { type: 'contains', value: '' }
      if (!['image'].includes(key)) searchParamObj[key] = param
    })
  })
  return searchParamObj
}


export const searchParamToQueryParams = (searchParamObj: SearchParamObj, searchFields: string[]) => {
  const queryParams = {};
  if (searchParamObj) {
    Object.entries(searchParamObj).forEach(([fieldName, searchParam]) => {
      if (searchFields.includes(fieldName)) {
        /** ネストされたオブジェクトをURLSearchでパースできる形に変換する。 */
        queryParams[`${fieldName}__${searchParam.type}`] = searchParam.value;
      }
    });
  }

  return queryParams
}


/** フォームをfetchした後にerrorが返却された場合に階層化されたエラーを表示できる形に変更する */
export const deconstractError = (content, formConfig: FormConfig, message: string) => {
  if (Array.isArray(content)) {
    if (content) {
      let section = ''
      content.forEach((childContent, index) => {
        console.log(childContent);

        if (Object.keys(childContent).length) {
          section += `<li class="error-content error-color">選択肢${index + 1}: ${deconstractError(childContent, formConfig, '')}</li>\n`
        }
      })
      if (section.length) message += `<ul>${section}</ul>\n`
    }
  } else if (content instanceof Object) {
    message += '<ul>'
    Object.entries(content).forEach(([key, childContent]) => {
      const mergedConfig = mergeFormConfig(formConfig)
      let keyString = key
      if (Object.keys(mergedConfig).includes(key)) {
        keyString = mergeFormConfig(formConfig)[key].options.label
      }
      if (Array.isArray(childContent)) {
        if (childContent.length && typeof childContent[0] == 'string') {
          message += `<li class="error-content error-color">${keyString}: ${childContent.join()}</li>\n`
        } else {
          message += `<li class="error-content error-color">${keyString}: ${deconstractError(childContent, formConfig, '')}</li>\n`
        }
      }
    })
    message += '</ul>\n'
  } else if (typeof content == 'string') {
    message += content
  }
  return message
} 