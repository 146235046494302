<script lang="ts">
  import type {
    ChoiceValue,
    FieldConfigObj,
    FormConfig,
    OptionValue,
    SearchParamObj,
    FieldConfig,
  } from 'scripts/utils/types';
  import RangeInput from '../input/RangeInput.svelte';
  import { createEventDispatcher } from 'svelte';
  import SlideCollapse from '../SlideCollapse.svelte';
  import PanelHead from '../PanelHead.svelte';
  import {
    defaultFieldConfig,
    defaultFieldOptions,
    mergeFormConfig,
  } from 'scripts/utils/utils';
  import ChoiceInput from '../input/ChoiceInput.svelte';
  import SmallCloseBtn from '../button_parts/SmallCloseBtn.svelte';
  import SmallPlusButton from '../button_parts/SmallPlusButton.svelte';
  import ModalQrcodeSearch from '../ModalQrcodeSearch.svelte';

  export let searchParamObj: SearchParamObj;
  /** formConfigは新規登録など、本来の目的で使用する際に使用するため、こちらには手を加えない */
  export let formConfig: FormConfig;
  export let hiddenFields: string[] = [];
  export let searchFields = [];

  // QRコード検索用
  export let apiEndpoint = '';
  export let detailEndpoint = '';
  export let primaryKey = 'id';

  let mergedFormConfig: FieldConfigObj = {};

  let collapseExpand = true;
  let defaultSelectConfig = { ...defaultFieldConfig };

  /** このコンポーネントではFormConfigRowの情報は不必要なのでmergeFormConfigを使用して取り除く。
   * 情報を取り除いた後はスプレッド構文でObjectをコピーし、格納する値がFormConfigに影響を与えないようにする
   */
  const makeMergedFormConf = (formConfig: FormConfig) => {
    mergedFormConfig = { ...mergeFormConfig(formConfig) };
  };

  /** 検索条件を選択するセレクタのラベルを作成 */
  const makeDefaultSelectConfig = (mergedFormConfig: FieldConfigObj) => {
    let selectLabels: ChoiceValue[] = Object.entries(mergedFormConfig).map(
      ([key, config]) => {
        return {
          value: key,
          display_name: config.options.label,
          hidden: hiddenFields.includes(key) ? true : false,
        };
      },
    );
    const defaultSelectOptions: OptionValue = {
      ...defaultFieldOptions,
      type: 'field',
      label: '',
      choices: selectLabels,
    };
    defaultSelectConfig = {
      ...defaultFieldConfig,
      component: ChoiceInput,
      options: defaultSelectOptions,
      label_display: false,
      inputClass: '',
    };
  };

  /** 検索タイプをcontainsとexactで切り替えられるようにする */
  const typeSelectOptions: OptionValue = {
    ...defaultFieldOptions,
    type: 'field',
    label: '',
    required: true,
    choices: [
      {
        value: 'contains',
        display_name: 'を含む',
      },
      {
        value: 'exact',
        display_name: 'と一致',
      },
    ],
  };
  let typeSelectConfig: FieldConfig = {
    ...defaultFieldConfig,
    component: ChoiceInput,
    options: typeSelectOptions,
    label_display: false,
    inputClass: '',
  };

  const dispatch = createEventDispatcher();
  const onSearchClick = () => {
    const storeSearchParams = {};
    searchFields.forEach((fieldName) => {
      if (Object.keys(searchParamObj).includes(fieldName))
        storeSearchParams[fieldName] = searchParamObj[fieldName];
    });

    dispatch('searchClick', storeSearchParams);
  };
  $: makeMergedFormConf(formConfig);
  $: makeDefaultSelectConfig(mergedFormConfig);
  // $: console.log(searchParamObj);
</script>

{#if searchParamObj}
  <div class="accordion-container">
    <PanelHead bind:display={collapseExpand} color="#eee">詳細検索</PanelHead>
    <SlideCollapse bind:expand={collapseExpand}>
      {#each searchFields as fieldName}
        <div class="row d-flex align-items-center" style="height:3.5rem;">
          <div class="col-2">
            <ChoiceInput bind:value={fieldName} config={defaultSelectConfig} />
          </div>
          {#if fieldName}
            {#if searchParamObj[fieldName].type == 'range'}
              <RangeInput
                bind:values={searchParamObj[fieldName].value}
                config={mergedFormConfig[fieldName]}
              />
            {:else if searchParamObj[fieldName].type == 'exact' || searchParamObj[fieldName].type == 'contains'}
              <svelte:component
                this={mergedFormConfig[fieldName].component}
                bind:value={searchParamObj[fieldName].value}
                config={{
                  ...mergedFormConfig[fieldName],
                  inputClass: 'col-7',
                  label_display: false,
                }}
              />
              {#if !mergedFormConfig[fieldName].options.choices?.length}
                <div class="col-2 px-0">
                  <ChoiceInput
                    bind:value={searchParamObj[fieldName].type}
                    config={typeSelectConfig}
                  />
                </div>
              {/if}
            {/if}
          {:else}
            <div class="col-4" />
          {/if}
          <div class="col-1">
            <SmallCloseBtn
              on:click={() => {
                searchFields.splice(searchFields.indexOf(fieldName), 1);
                searchFields = searchFields;
              }}
            />
          </div>
        </div>
      {/each}
      <div class="row">
        <div class="col-2 d-flex justify-content-center">
          <SmallPlusButton
            on:click={() => {
              searchFields.push('');
              searchFields = searchFields;
            }}
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button on:click={onSearchClick} class="mx-3 btn btn-primary">
          検索
        </button>
        {#if apiEndpoint}
          <div class="mx-3">
            <ModalQrcodeSearch {apiEndpoint} {detailEndpoint} {primaryKey} />
          </div>
        {/if}
      </div>
    </SlideCollapse>
  </div>
{/if}

<style>
  .accordion-container {
    width: 100%;
    border: 0.5px solid #ddd;
    border-radius: 0.3rem;
  }
</style>
