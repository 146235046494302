<script lang="ts">
  import { Icon } from '@smui/icon-button';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  const onClick = (e) => {
    dispatch('click', e);
  };
</script>

<div class="closeButtonContainer">
  <button
    on:click={onClick}
    class="mb-3 icon-button d-flex justify-content-center"
  >
    <Icon style="font-size:1rem;" class="material-icons">close</Icon>
  </button>
</div>

<style>
  .icon-button {
    background-color: #ddd;
    color: #555;
    border: none;
    border-radius: 1rem;
  }
</style>
